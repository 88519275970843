import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  getCustomerId,
  getCustomerName,
  removeCustomerId,
} from "utility/HeaderConfig";
import { Link, useHistory } from "react-router-dom";
import tw from "twin.macro";
import { useCartContext } from "context/UseCartContext";
import toast, { Toaster } from "react-hot-toast";
import OpenModal from "components/OpenModal";

export const NavLink = tw.a`no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg`;

export default function TopbarMenus() {
  let history = useHistory();
  const { login, dispatch } = useCartContext();

  const logout = () => {
    removeCustomerId();
    dispatch({ type: "LOGIN", payload: null });
    dispatch({ type: "ADD", payload: null });
    reactLocalStorage.remove("CartList");
    toast.success("Logged out successfully...!", {
      duration: 1000,
      position: "top-right",
    });
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const checkLogin = () => {
    if (getCustomerId()) {
      history.push("/MyScheme");
    } else {
      history.push("/LoginPage");
    }
  };

  return (
    <div tw="xl:flex-1 lg:flex-grow-0  z-50">
      <div tw="xl:flex-1 lg:flex-grow-0 text-right">
        <Toaster position="top-right" />
        <Link
          to="/Offers"
          tw="no-underline text-black transition duration-300 text-black hover:text-custom-100"
        >
          <span tw="mx-3 pr-4 border-r border-gray-500  text-xs sm:text-sm lg:text-xs xl:text-sm">
            Offers
          </span>
        </Link>
        <Link
          to="/BookAnAppointment"
          tw="no-underline text-black transition duration-300 text-black hover:text-custom-100"
        >
          <span tw="mx-3 pr-4 border-r border-gray-500 text-xs sm:text-sm lg:text-xs xl:text-sm">
            Book An Appointment
          </span>
        </Link>
        <span
          className="hover-trigger"
          tw="text-xs sm:text-sm lg:text-xs xl:text-sm relative cursor-pointer"
        >
          My Account<i className="bi bi-caret-down" tw="text-base mx-1"></i>
          <div
            className="hover-target"
            tw="absolute right-0 text-left bg-white w-40   py-2 rounded-md shadow-xl"
          >
            {login ? (
              <>
                <div
                  tw=" text-center uppercase pb-2 no-underline text-black hover:text-black block  hover:(-translate-y-2 ) duration-300 transition ease-in-out delay-150"
                  className="border-bottom"
                >
                  {getCustomerName()}
                </div>
                <span
                  onClick={checkLogin}
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i
                    className="bi bi-person-workspace"
                    tw=" px-3 text-base"
                  ></i>
                  My Scheme
                </span>
                <Link
                  to="/WishList"
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i className="bi  bi-heart" tw=" px-3 text-base"></i>
                  My Wishlist
                </Link>
                <Link
                  to="/MyPurchase"
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i className="bi  bi-bag" tw=" px-3 text-base"></i>
                  My Purchase
                </Link>
                <Link
                  to="/AddAddress"
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i className="bi  bi-geo-alt-fill" tw=" px-3 text-base"></i>
                  My Address
                </Link>
                <span
                  onClick={logout}
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i
                    className="bi bi-box-arrow-in-left"
                    tw="px-3 text-base"
                  ></i>
                  Logout
                </span>
              </>
            ) : (
              <>
                <span
                  onClick={() => {
                    dispatch({ type: "MODAL", payload: 'login' });
                  }}
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i
                    className="bi bi-box-arrow-in-right"
                    tw="mr-3 px-3 text-base"
                  ></i>
                  Login
                </span>
                <span
                  onClick={() => {
                    dispatch({ type: "MODAL", payload: "register" });
                  }}
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i className="bi bi-person-fill" tw="mr-3 px-3 text-base"></i>
                  Register
                </span>
                <span
                  onClick={() => {
                    dispatch({ type: "MODAL", payload: "user" });
                  }}
                  tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg"
                >
                  <i className="bi bi-person-fill" tw="mr-3 px-3 text-base"></i>
                  User Account
                </span>
              </>
            )}
          </div>
        </span>
      </div>
      <OpenModal />
    </div>
  );
}
