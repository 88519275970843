import React, { useEffect, useState } from "react";
import { getPageProductType } from "api/ProductTypeApi";
import tw from "twin.macro";
import Noimg from "../images/no_img.jpg";
// import gold from "../images/productType/GOLD.png";
// import silver from "../images/productType/SILVER.png";
// import diamond from "../images/productType/DIAMOND.png";
// import gift_articles from "../images/productType/GIFT_ARTICLES.png";
// import silver_articles from "../images/productType/SILVER_ARTICLES.png";
// import coin from "../images/productType/COIN.png";
// import platinum from "../images/productType/PLATINUM.png";
import { useHistory } from "react-router-dom";
import { dataList } from "api/SkeletonData.js";
import Skeleton from "react-loading-skeleton";



const Container = tw.div`relative`;
const Heading = tw.h1`text-center  block text-black  text-4xl m-0`;

export default function ProductType() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lists,setLists] = useState([
    "gold","silver","diamond","platinum"
  ])
  const history = useHistory();
  useEffect(() => {
    getProductType();
    //eslint-disable-next-line
  }, []);

    const getProductType = () => {
      const arr1 = [];
      const arr2 = [];
      let isChecked = false
      let equlprod = []
      let notEqulprod = []
      setLoading(true);
      let filterData ={
        active:1
      };
      getPageProductType(filterData).then((data) => {
        let response = data.response.content;
       lists.forEach((item,idx) =>{
        if(!isChecked){
          equlprod.length = 0        
          arr1.push(...response.filter((x)=>x.name.toLowerCase() === item))
        arr2.push(...response.filter((x)=>x.name.toLowerCase() !== item))
          isChecked = true
        }else{
          arr1.push(...arr2.filter((x)=>x.name.toLowerCase() === item))
          const newarr = arr2
           notEqulprod = newarr.filter((x)=>(x.name).toLowerCase() !== item)
          arr2.length = 0
          arr2.push(...notEqulprod)
        }
        if(lists.length-1 === idx){
          arr1.push(...arr2)
        }
       })
      setLoading(false);
      setData(arr1);
      });
    };
    
  return (
    <Container className="container py-3 py-sm-2">
      <div className="container py-sm-3 text-center mb-2 mb-sm-4">
        <div tw="flex justify-center py-2">
          <Heading>Shop By Metal Type</Heading>
        </div>
        <small>Explore our latest designs curated just for you!</small>
      </div>
      <div className="py-sm-0 py-3 ttaboutbanner1  left-to-right hb-animate-element hb-in-viewport">
        <div className="row">
          {loading &&
            dataList.slice(0, 6).map((data , index) => (
              <div className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3" key={index}>
                  <Skeleton
                        className="group"
                        tw="h-72  mb-1 border border-gray-300 rounded-lg flex justify-center w-full "
                      />
              </div>
            ))}
          {data?.map((data) => {
              return (
                <div key={data.id} className="block1 ttaboutbanner col-6 col-sm-6  col-lg-4 position-relative mb-3 ">
                  <div
                    className="ttabout-img"
                    tw="relative p-0"
                    onClick={() => {
                      history.push("ProductList", { type: data });
                    }}
                  >
                    <span>
                    
                      <img
                        src={data.productImageUrl !== "-" ? data.productImageUrl : Noimg }
                        alt="cms-01.jpg"
                        className=" rounded"
                        tw="h-80 w-96"
                        // style={{ width: "400px",height: "300px"}}
                      />
                    </span>
                  </div>
                  <div
                    className="ttbanner-title text-uppercase top-0"
                    tw="text-xs sm:text-base"
                  >
                    {data.name}
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </Container>
  );
}
