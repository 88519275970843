import axios from 'axios';
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from '../utility/HeaderConfig';

const getAllPaymentMode = () => {
  return axios
    .get(
      `${BASE_URL}/admin/accounts/paymentmode/metadata/get-all`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const createSales = (requestBody: Object) => {
  return axios
    .post(`${BASE_URL}/salesestimation/sales/addsales/create`, requestBody, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getAllExpenseCategory = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/admin/accounts/expensescategory/get-all?expensesCategoryFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getAllOrderStatus = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/admin/others/orderstatus/get-all?filters=${JSON.stringify(filters)}`,
      getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getAllFinancialYear = async (filters: Object) => {
  const responseData = await fetch(
    `${BASE_URL}/admin/accounts/financialyear/metadata/get-all?metaFinancialYearFilter=${JSON.stringify(filters)}`,
    getHeaderConfig()
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.error(error);
    });
  return responseData;
};

const getByLedgerName = async (name: String) => {
  const responseData = await fetch(
    `${BASE_URL}/admin/accounts/meta/ledger/name/get-by-name?name=${name}`,
    getHeaderConfig()
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.error(error);
    });
  return responseData;
};

const createEstimation = (requestBody: Object)=>{
  return axios
    .post(
      `${BASE_URL}/salesestimation/estimation/viewestimation/estimation/create`,requestBody,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
}


export {
  getAllPaymentMode,
  getAllExpenseCategory,
  getAllOrderStatus,
  createSales,
  getAllFinancialYear,
  getByLedgerName,
  createEstimation
};