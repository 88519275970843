import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Offers } from "../api/OffersApi";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import special_offer from "images/offers/special_offer.png";
import { dateMonthYearFormatUtcToLocal } from "../utility/Constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { dataList } from "api/SkeletonData";
import no_data from "images/no_data.png";
import moment from "moment";

const Container = tw.div`relative font-display overflow-hidden`;
const Heading = tw.h1`text-center font-dancing block text-black  text-5xl m-0 text-white`;
const BannerImg = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded-t w-full py-24 bg-center`}
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ configImages }) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getOffers();
    getBanner();
    //eslint-disable-next-line
  }, []);

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "offer") {
          setBanner(data.value);
        }
      });
  };

  const getOffers = () => {
    const enrollmentDate = `${moment().format("YYYY-MM-DD")}`;
    const enrollmentTime = `${moment().format("00:00:00")}`;
    const enrollmentDateWithTime = `${enrollmentDate}T${enrollmentTime}Z`;
    setLoading(true);
    const offerFilterData = {
      // offerType: "WEB_OFFER",
      validOffersDate: enrollmentDateWithTime,
      // visibilityMode: "WEB"
      offerType: "MOBILE_OFFER",
    };
    Offers(offerFilterData).then(
      (data) => {
        setOffers(data.response.content);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <Container>
      <BannerImg tw="flex justify-center" imageSrc={banner}>
        <Heading>Offers</Heading>
      </BannerImg>
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /<span tw="ml-3 text-custom-100 capitalize tracking-wide ">Offer</span>
      </div>
      <section tw="py-10 relative">
        <div className="container-md">
          {loading && (
            <div className="row ">
              {dataList.slice(0, 8).map((data) => (
                <div className="col-md-4 py-2">
                  <Skeleton tw="w-full" style={{ height: "200px" }} />
                  <div className="card my-2 text-center py-2">
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                    <Skeleton className="px-3" tw="w-11/12" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {offers.length === 0 && !loading ? (
            <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
          ) : (
            <div className="row align-items-center">
              {offers.map((item) => (
                <>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 py-3">
                    <div className="rounded" tw="hover:shadow-2xl">
                      <img
                        src={
                          item.offerImageString !== null &&
                          item.offerImageString !== "" &&
                          item.offerImageString !== "-" &&
                          item.chitImage !== "Image_Not_Available"
                            ? item.offerImageString
                            : Noimg
                        }
                        alt="schemeImage"
                        className="w-100"
                        style={{ height: "261px" }}
                      />
                      <div
                        className="box bg-light position-relative mw-100"
                        tw="top-0 left-0 translate-x-0 transform-none "
                      >
                        <div className="box-inner p-0">
                          <div className="p-3" tw="bg-gray-100 ">
                            <div className="row align-items-center ">
                              <div className="col-6">
                                <h6 tw="text-lg text-custom-100 mb-2 uppercase">
                                  {item.offerDetails}
                                </h6>
                                <p
                                  className="mb-1 align-self-end  align-content-end "
                                  tw="text-sm"
                                >
                                  Description
                                </p>
                              </div>
                              <div className="col-6">
                                <img
                                  src={special_offer}
                                  alt="Zinger Jewellers Offer"
                                  tw="w-2/6"
                                  className="m-auto"
                                />
                              </div>
                            </div>

                            <p tw="mb-2 text-sm  text-xs">
                              {item.description !== null &&
                              item.description !== "" &&
                              item.description !== "-"
                                ? item.description
                                : "No Description"}
                            </p>
                            <div className="d-flex justify-content-between">
                              <div>
                                <span tw="font-medium">Valid From</span> :{" "}
                                <span tw="text-xs">
                                  {" "}
                                  {dateMonthYearFormatUtcToLocal(
                                    item.validFrom
                                  )}
                                </span>
                              </div>
                              <div>
                                <span tw="font-medium">Valid To</span> :{" "}
                                <span tw="text-xs">
                                  {dateMonthYearFormatUtcToLocal(item.validTo)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </section>
    </Container>
  );
};
