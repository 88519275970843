import React, { useEffect, useState } from 'react';
import { Heading } from 'pages/BookAnAppointment';
import { NavLink } from './ProductCategory';
import Noimg from "../images/no_img.jpg";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { getProduct, orderStatus } from 'api/ProductApi';
import { currentRegister } from 'utility/Constant';
import no_data from "images/no_data.png";
import Carousel from "react-elastic-carousel";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const CardContainer = tw.div`mt-4 w-full`;
const Card = tw(
  motion.a
)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded `}
`;


function TrendOnShop() {

  const history = useHistory();

 const [trendOnProduct, setTrendOnProduct] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
     Promise.all([
       getOrderStatusSale(),
       getOrderStatusTransfer(),
       getOrderStatusSold()
     ])
     .then((result) => {
       getTrendOnShopData(result[0],result[1],result[2]);
     })
     .catch((err) => console.error(err));
       //eslint-disable-next-line
     }, []);

 const getOrderStatusSale = () => {
    return orderStatus({orderStatusName : "sale"})
    .then((data) => {
      return data.response[0].id;
    })
    .catch((err) =>console.error(err));
  }

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getTrendOnShopData = (
    orderSaleStatus,
    orderTransferStatus,
    orderSoldStatus
  ) =>{
    setLoading(true)
    getProduct(10,{
      productLabel: 'TrendOnShop',
      removeNTP: 'NTP-',
      removeHP: 'HP-',
      beforeCreatedAt: currentRegister(),
      sortType: true,
      statusId: `${orderSaleStatus},${orderTransferStatus},${orderSoldStatus}`,
      demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
    })
    .then((data) => {
    setTrendOnProduct(data.response.content);
    setLoading(false)
    })
    .catch((err) => {
      console.error(err);
    })
  } 

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];


  return (
    <>
    <div className="container py-2 text-center ">
    <div tw="flex justify-center">
      <Heading>Trend On Shops</Heading>
    </div>
  </div>
  <div className="container">
    {trendOnProduct.length === 0 ? ( 
 <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
     )  
    : (
      <Carousel
        itemPadding={[0, 10]}
        itemsToShow={5}
        outerSpacing={0}
        showEmptySlots
        breakPoints={breakPoints}
        className="newArrivalsComponent"
      >
        {trendOnProduct.map((categoryItem, index) => ( 
           <div className="w-100" key={index}>
            <CardContainer
              tw="cursor-pointer relative"
              onClick={()=>
                history.push("TrendOnShopComponent",{
                  category : categoryItem,
                })
              }
            >
              <Card className="group position-relative ">
                <div className="overflow-hidden">
                  <CardImageContainer
                    className="group"
                    tw="h-64 border border-gray-300 rounded-t-lg flex justify-center w-full  transform transition duration-500  group-hover:(scale-125 rotate-12)"
                    imageSrc={
                      categoryItem.images === "Image_Not_Available" || categoryItem.images === "-" || categoryItem.images === null || categoryItem.images ==="@#@"
                        ? Noimg
                        : categoryItem.images.split("@#@")[0]
                    }
                  ></CardImageContainer>
                </div>
                <div tw="text-center  border border-gray-300  font-semibold bg-white px-5 py-3 text-black text-xs uppercase">
                  <NavLink className="mb-2">
                     {categoryItem.category} 
                  </NavLink>
                   <span tw="text-custom-100">
                    Explore{" "}
                    <i className="mx-1 bi bi-arrow-up-right-circle-fill"></i>
                  </span> 
                </div>
              </Card>
            </CardContainer>
          </div> 
         ))}
      </Carousel>
    )} 
    <div className="d-flex justify-content-center py-0 sm:py-3">
      <div
        type="button"
        tw="transition-all duration-700 mt-4 border border-custom-100 hover:text-white hover:bg-custom-100 px-20 py-2 text-sm mb-4"
        onClick={() => history.push("/TrendOnShopComponent")}
      >
        {loading ? "Loading ..." : "View More"} 
      </div>
    </div>
  </div>
  </>
  )
}

export default TrendOnShop