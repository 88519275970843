import tw from "twin.macro";
import AboutUs from "../components/AboutUs.js";

const Container = tw.div`relative font-display overflow-hidden`;

export default ({branch}) => {
  return (
      <Container>
        <AboutUs  branch={branch}/>
      </Container>
  );
};
