import { createEstimation, getAllFinancialYear, getAllOrderStatus } from '../api/CheckOutApi';
import { dayClose } from '../api/NewSchemeApi';
import { ProductDetails } from '../api/ProducDetailsApi';
import { getBranchId, getCustomerId } from './HeaderConfig';

const getDefaultFinancialYr = async () => {
  const filterData = {
    isDefault: 1
  };
  const responseData = getAllFinancialYear(filterData);
  responseData.then((data) => { });
  return responseData;
};

const getOrderStatusId = async (statusName) => {
  const filterData = {
    orderStatusName: statusName,
  };
  const responseData = getAllOrderStatus(filterData);
  responseData.then((data) => { });
  return responseData;
};

const getDayCloseData = async (moduleName: String, branchId: String) => {
  const filterData = {
    module: moduleName,
    branchId,
  };
  const responseData = await dayClose(filterData);
  return responseData;
};

const createEstimationData = async (CartItem,saleRelatedDetails) =>{
  let totalEstiAmount = 0;
  let createEstiResData = '';
const estiItemData = await prepareSaleEstiItemData(CartItem,saleRelatedDetails,'Estimation')
if(estiItemData.length > 0) {
  const getCustomerDetailsRes = getCustomerId();
  totalEstiAmount = CartItem.reduce((sum, i) => {
      return sum + Math.trunc(i.total)
    }, 0)
    const estimationDetails = {
      branchDetails: getBranchId(),
      counterNo: null,
      currentRate: parseFloat(estiItemData[0].price),
      estimationItem: estiItemData,
      estimationType: 'ESTIMATION',
      exchangeAmount: 0,
      soldTo: {
        id: getCustomerDetailsRes
      },
     status: saleRelatedDetails.estiOrderStatusId,
    // totalAmt: parseFloat(totalEstiAmount).toFixed(2),
    totalAmt: totalEstiAmount,
    // totalAmtWithoutDiscount: parseFloat(totalEstiAmount).toFixed(2),
    totalAmtWithoutDiscount: totalEstiAmount,
    discountAmt: 0,
    paymentMode: JSON.stringify([]),
    estimationExchange: null,
    estimationExchangeId: [],
    estimationMode: 'ECOMM_WEB'
    };
    createEstiResData =await createEstimation(estimationDetails)
  }
return createEstiResData;
};



const prepareSaleEstiItemData = async (CartItem,saleRelatedDetails ,moduleName) => {
  let estiSaleItemsList = [];
  await Promise.all(
    CartItem.map(async (CartItem)=>{
      const productResData = await ProductDetails(CartItem.productId);
      const { todayRate, productCode, basePrice, ocAmt } = CartItem;
      if (productResData && productResData.response.id) {
        const { weight, productName,  pieceRate, piece, wastageType, mcType, otherCharges, wastageValue, mcValue, netMc } = productResData.response;
        const otherChargeData = JSON.parse(otherCharges);
        let diamondBasePrice = 0;
        let diamondTax = 0;
        let diamondTotal = 0;
        if (CartItem.diamondBasePrice !== undefined) {
          diamondBasePrice = CartItem.diamondBasePrice;
          diamondTax = CartItem.diamondTax;
          diamondTotal = parseFloat(diamondBasePrice) + parseFloat(diamondTax);
        }
        let saleWastageType = 'flat';
        let saleMCType = 'flat';
        let saleWastageUnit = 'percentage';
        let saleMCUnit = 'rs';

        if (wastageType === 'mg') {
          saleWastageUnit = 'mg';
          saleWastageType = 'pergm';
        } else if (wastageType === 'rs') {
          saleWastageUnit = 'rs';
          saleWastageType = 'flat';
        } else if (wastageType === 'percentage') {
          saleWastageUnit = 'percentage';
          saleWastageType = 'flat';
        } else if (wastageType === 'pieces') {
          saleWastageUnit = 'pieces';
          saleWastageType = 'flat';
        }

        if (mcType === 'mg') {
          saleMCType = 'pergm';
          saleMCUnit = 'rs';
        } else if (mcType === 'rs') {
          saleMCType = 'flat';
          saleMCUnit = 'rs';
        } else if (mcType === 'percentage') {
          saleMCType = 'flat';
          saleMCUnit = 'percentage';
        } else if (mcType === 'pieces') {
          saleMCType = 'flat';
          saleMCUnit = 'pieces';
        }

        let mcGramType = '';
        let mcGramUnit = '';
        let mcRateType = null;
        let mcRateUnit = null;
        let saleMcAmount = 0;
        let saleMcWeight = 0;
        if (saleMCType === 'pergm') {
          mcGramType = saleMCType;
          mcGramUnit = saleMCUnit;
          saleMcWeight = mcValue;
        } else {
          mcRateType = saleMCType;
          mcRateUnit = saleMCUnit;
          saleMcAmount = mcValue;
        }

        const productWeight = JSON.parse(weight);
        const primaryTaxTotal = CartItem.tax.reduce((sum, i) => {
          return sum + (i.amount)
        }, 0);
        const estiSaleWt = {
          grossWeight: parseFloat(productWeight.grossWeight).toString(),
          lessWeight: parseFloat(productWeight.lessWeight).toString(),
          netWeight: parseFloat(productWeight.netWeight).toString(),
          wastageValue: parseFloat(wastageValue).toString(),
          mcValue: parseFloat(mcValue).toString(),
          balanceLess: '0',
          balanceNet: '0',
          balanceGross: '0',
          manipulationData: {
            mcGramType,
            mcGramUnit,
            mcRateType,
            mcRateUnit,
            wastageType: saleWastageType,
            wastageUnit: saleWastageUnit,
            mcType: saleMCType,
            mcUnit: saleMCUnit
          },
          isDiscountEnable: true,
          isChitWtAdjustEnable: false,
          totalDisbmtGrams: 0,
          actualBasePrice: basePrice,
          actualMakingCharges: parseFloat(netMc).toString(),
          actualNetWt: parseFloat(productWeight.netWeight).toString(),
          actualGrossWt: parseFloat(productWeight.grossWeight).toString(),
          chitAdjustAfterBalNwt: 0,
          chitAdjustAfterBalGwt: 0,
          adjustedChitWt: 0,
          actualWastage: parseFloat(wastageValue).toString(),
          chitNetWeight: 0,
          chitAdjustBalanceWt: 0,
          chitAdjustType: "",
          finalBasePrice: parseFloat(basePrice).toFixed(2),
          actualMC: parseFloat(netMc).toString(),
          finalMC: parseFloat(netMc).toString(),
          actualWst: parseFloat(wastageValue).toString(),
          finalWstVal: parseFloat(wastageValue).toString(),
          actualTax: parseFloat(primaryTaxTotal).toFixed(2),
          finalTax: parseFloat(primaryTaxTotal).toFixed(2),
          actualWstGrm: 0,
          acutualMcGrm: 0,
          finalMcGrm: 0,
          finalWstGrm: 0,
          mcBenefit: 0,
          wstBenefit: 0,
          chitWtAdjustValue: 0,
          chitMcValue: 0,
          chitWastageValue: 0,
          mcAmt: parseFloat(netMc).toFixed(2)
        };

        if (moduleName === 'Estimation') {
          estiSaleWt.basePrice = basePrice;
          estiSaleWt.isChitWtAdjustEnable = false;
          estiSaleWt.totalDisbmtGrams = '0';
          estiSaleWt.chitAdjustBalanceWt = parseFloat(productWeight.netWeight).toString();
        } else {
          estiSaleWt.isSp = false;
          estiSaleWt.actualBasePrice = 0;
          estiSaleWt.adjustBasePrice = 0;
          estiSaleWt.actualMakingCharges = 0;
          estiSaleWt.adjustMakingCharges = 0;
          estiSaleWt.actualWastage = 0;
          estiSaleWt.adjustWastage = 0;
          estiSaleWt.chitNetWeight = 0;
        }

        let stoneChargeList = [];
        const stoneChargeDetails = [];
        const otherChargeDetails = [];
        const basePriceWithoutOthercharge = parseFloat(basePrice) - parseFloat(ocAmt);
        otherChargeData.forEach(otherChargeItem => {
          if ({}.hasOwnProperty.call(otherChargeItem, 'stone')) {
            if (otherChargeItem.stone.length > 0) {
              stoneChargeList = otherChargeItem.stone;
            }
          } else if (
            otherChargeItem.charge &&
            otherChargeItem.remarks !== 'TotalOtherChargeAmt'
          ) {
            let otherChargeItemAmt = 0;
            if (otherChargeItem.type === 'Flat') {
              otherChargeItemAmt = parseFloat(otherChargeItem.charge);
            } else if (otherChargeItem.type === 'Percentage') {
              otherChargeItemAmt = (parseFloat(otherChargeItem.charge) * parseFloat(basePriceWithoutOthercharge)) / 100;
            } else if (otherChargeItem.type === 'Pieces') {
              otherChargeItemAmt = parseFloat(otherChargeItem.charge) * parseFloat(piece);
            } else if (otherChargeItem.type === 'Pergram') {
              otherChargeItemAmt = parseFloat(otherChargeItem.charge) * parseFloat(productWeight.netWeight);
            }
            otherChargeDetails.push({
              charge: parseFloat(otherChargeItem.charge),
              piece: parseFloat(piece),
              remarks: otherChargeItem.remarks === 'Hall Mark'
                ? 'hallmarkcharge'
                : otherChargeItem.remarks,
              type: otherChargeItem.type,
              total: parseFloat(otherChargeItemAmt).toFixed(2)
            });
          }
        });

        stoneChargeList.forEach(stoneItem => {
          let shape = '';
          let cutType = '';
          let clarity = '';
          let color = '';
          let purity = '';
          if (stoneItem.stoneDescription) {
            stoneItem.stoneDescription.forEach(stoneDescRes => {
              if (stoneDescRes.key === 'shape') {
                shape = stoneDescRes.value;
              } else if (stoneDescRes.key === 'cut') {
                cutType = stoneDescRes.value;
              } else if (stoneDescRes.key === 'clarity') {
                clarity = stoneDescRes.value;
              } else if (stoneDescRes.key === 'color') {
                color = stoneDescRes.value;
              } else if (stoneDescRes.key === 'purity') {
                purity = stoneDescRes.value;
              }
            });
          }

          const stoneDetailsArray = [];
          const stoneMetaPurityValue = {
            key: 'purity',
            value: purity ? purity : ''
          };
          const stoneMetaColorValue = {
            key: 'color',
            value: color ? color : ''
          };
          const stoneMetaClarityValue = {
            key: 'clarity',
            value: clarity ? clarity : ''
          };
          const stoneMetaShapeValue = {
            key: 'shape',
            value: clarity ? shape : ''
          };
          const stoneMetaCutUnCutValue = {
            key: 'cut',
            value: cutType ? cutType : ''
          };
          stoneDetailsArray.push(
            stoneMetaPurityValue,
            stoneMetaColorValue,
            stoneMetaClarityValue,
            stoneMetaShapeValue,
            stoneMetaCutUnCutValue
          );
          stoneChargeDetails.push({
            grm: stoneItem.grm,
            cost: pieceRate > 0 && (stoneItem.dataName === undefined ||
              (stoneItem.dataName !== undefined &&
                stoneItem.dataName.toLowerCase() !== 'diamond')) ? 0 : stoneItem.cost,
            remark: stoneItem.remark,
            typeId: stoneItem.typeId,
            typeName: stoneItem.typeName,
            rateType: stoneItem.rateType,
            pieces: stoneItem.pieces,
            size: stoneItem.size ? stoneItem.size : '',
            stoneDescription: stoneDetailsArray,
            materialType: stoneItem.dataName !== undefined ? stoneItem.dataName : ''
          });
        });
        if (stoneChargeDetails.length > 0)
          otherChargeDetails.push({ stone: stoneChargeDetails });

        otherChargeDetails.push({
          piece: 0,
          charge: 0,
          charge: parseFloat(ocAmt),
          type: '',
          remarks: 'TotalOtherChargeAmt'
        });

        let secWeightDetails = [];

        if (moduleName === 'Estimation') {
          secWeightDetails = CartItem.secDetails;
        } 

        const estiSaleItemObj = {
          classificationColorCode: productResData.response.classificationColorCode,
          classificationShortCode: productResData.response.classificationShortCode,
          currentRate: parseFloat(todayRate),
          discount: 0,
          hallmarkCharge: productResData.response.hallmarkCharge,
          hmTaxPercentage: productResData.response.hmTaxPercentage,
          piece: parseFloat(piece),
          pieceRate: parseFloat(pieceRate),
          productId: CartItem.productId,
          soldBy: saleRelatedDetails.onlinePaymentEmplyId,
          tax: parseFloat(primaryTaxTotal).toFixed(2),
          total: Math.trunc(parseFloat(primaryTaxTotal) + parseFloat(basePrice) + parseFloat(productResData.response.hallmarkCharge) + parseFloat(productResData.response.hallmarkCharge) * parseFloat(productResData.response.hmTaxPercentage) / 100),
          price: parseFloat(todayRate),
          basePrice: parseFloat(basePrice).toFixed(2)
        };

        if (moduleName === 'Estimation') {
          estiSaleItemObj.additionalCharge = JSON.stringify(otherChargeDetails);
          estiSaleItemObj.estimationMCAmount = saleMcAmount;
          estiSaleItemObj.estimationMCWeight = saleMcWeight;
          estiSaleItemObj.estimationSecondaryWeight = JSON.stringify(secWeightDetails);
          estiSaleItemObj.estimationWastage = wastageValue;
          estiSaleItemObj.estimationWeight = JSON.stringify(estiSaleWt);
          estiSaleItemObj.huId = productResData.response.huId;
          estiSaleItemObj.status = saleRelatedDetails.estiOrderStatusId;
          estiSaleItemObj.productTagNo = productCode;
          estiSaleItemObj.tagNo = productCode;
          estiSaleItemObj.productName = productName;
        } else {
          estiSaleItemObj.additionalCharges = JSON.stringify(otherChargeDetails);
          estiSaleItemObj.availableWeightAfterProcess = 0;
          estiSaleItemObj.basePrice = parseFloat(basePrice).toFixed(2);
          estiSaleItemObj.combinedProducts = null;
          estiSaleItemObj.deliveryStatus = 'yes';
          estiSaleItemObj.estimation = global.estimationId !== '' ? global.estimationId : null;
          estiSaleItemObj.hasPartlySale = 'No';
          estiSaleItemObj.pdtAdvItemId = null;
          // estiSaleItemObj.saleMcAmount;
          // estiSaleItemObj.saleMcWeight;
          estiSaleItemObj.saleSecondaryWeight = JSON.stringify(secWeightDetails);
          estiSaleItemObj.saleWastage = wastageValue;
          estiSaleItemObj.salesWeight = JSON.stringify(estiSaleWt);
          estiSaleItemObj.status = saleRelatedDetails.soldOrderStatusId;
          estiSaleItemObj.productTagNo = productCode;
          estiSaleItemObj.productName = productName;
          estiSaleItemObj.processChangeDate = null;
        }
        estiSaleItemsList.push(estiSaleItemObj);
      }
    })
    );
  return estiSaleItemsList;
};

export {
  getDefaultFinancialYr,
  getOrderStatusId,
  getDayCloseData,
  prepareSaleEstiItemData,
  createEstimationData
};