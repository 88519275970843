import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { getProduct } from "../api/ProductApi";
import 'react-loading-skeleton/dist/skeleton.css';
import { getBranchId } from "utility/HeaderConfig.js";
import { filterFormatToDate } from "utility/Constant.js";
import { genderBasedIcons } from "./GenderBasedIcons.js";
import { setOrderStatus, getOrderStatus } from "../utility/HeaderConfig.js";
import { orderStatus } from "../api/ProductApi";
import GenderSlider from "./GenderSlider.js";
import { dayClose } from "../api/NewSchemeApi";
import moment from "moment";


const Heading = tw.h1`text-center  block text-black  text-4xl m-0 pb-2`;
export const NavLink = tw.button`block no-underline text-black font-semibold m-auto`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-8 py-2   last:mr-0 inline-block text-black font-medium rounded-sm transition duration-300 text-xs sm:text-sm  text-center uppercase border border-dashed border border-custom-100`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100! `}
  }
`;


export default () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active , setActive ] = useState('M');
  const [dayCloseDate , setDayClose ] = useState("");

  
  useEffect(() => {
    orderstatus();   
   //eslint-disable-next-line
  }, []);


  const orderstatus = () => {
    orderStatus({ orderStatusName: "sale" }).then((data) => {
      setOrderStatus(data.response[0].id);
      dayCloseDay();
    });
  };

  
const dayCloseDay = () => {
  dayClose({
    module: "Sales",
    branchId: getBranchId(),
  }).then((data) => {
    if(data.response.length > 0){
      let dateString = data.response[0].activeDate;
      let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
      let dayCloseDate = date.format('YYYY-MM-DD');
      setDayClose(dayCloseDate);
      getCategoryList('M',dayCloseDate);
    }   
  });
};


  const getCategoryList = (gender , dayCloseDate) => {
    setActive(gender);
    setLoading(true);
    let filter = {
      branch: getBranchId(),
      removeNTP: "NTP-",
      removeHP: "HP-",
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      userType: gender,
      statusId : getOrderStatus(),
      allowCatalogue: 1,
      productDemoStatus : 'Available_For_Demo,Demo_Completed,Demo_Cancelled'
    };
    getProduct(20,filter).then((data) => {
        setCategoryList(data.response.content);
         setLoading(false)
      });
  };

  return (
    <div className="pb-5">
      <div className="container text-center py-sm-3">
        <div tw="flex justify-center">
          <Heading>
             Shop By Gender
          </Heading>
        </div>
        <small>Whatever the occasion, we've got a beautiful piece of jewellery for you.</small>
      </div>
      <div className="container position-relative">
            <div className="row justify-content-center pb-3">
              {genderBasedIcons.map((data , index)=>(
                  <div className="col-md-4 col-lg-2 col-6 text-center my-2" tw="cursor-pointer" onClick={()=>{getCategoryList(data.gender, dayCloseDate)}} key={index}>
                  <img src={data.imgageSrc} alt="men" className="m-auto" tw=" w-4/5 sm:w-8/12" />
                  <TabControl active={data.gender === active} className="rounded">
                    <span tw="font-semibold">{data.title}</span>
                  </TabControl>
                  </div>
              ))
              }
            </div>
        <div className="row justify-content-center">
            <GenderSlider categoryList={categoryList} Loading={loading}/>
        </div>
      </div>
    </div>
  );
};
