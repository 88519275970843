import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { currentRegister } from "utility/Constant";
import { getProduct, orderStatus } from "api/ProductApi";
import mostLikedImage from "images/MainpageBanners/mostLiked.png";
import Bangle from "images/MainpageBanners/bangles.png";
import Noimg from "../images/no_img.jpg";

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:(mr-2 my-2) last:mr-0 text-black font-medium rounded-sm transition duration-300 text-xs sm:text-sm w-1/2 sm:w-auto text-center uppercase border border-gray-400 border-solid`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

// eslint-disable-next-line
const Container = tw.div`text-black`;

function MostedLikedComponent() {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    Promise.all([
      getOrderStatusSale(),
      getOrderStatusTransfer(),
      getOrderStatusSold(),
    ])
      // eslint-disable-next-line
      .then((result) => {
        getMostLiked(result[0], result[1], result[2]);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line
  }, []);

  const getOrderStatusSale = () => {
    return orderStatus({ orderStatusName: "sale" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getMostLiked = (
    orderSaleStatus,
    orderTransferStatus,
    orderSoldStatus
  ) => {
    if (orderSaleStatus && orderTransferStatus && orderSoldStatus) {
      getProduct(10, {
        productLabel: "Most Liked Products",
        removeNTP: "NTP-",
        removeHp: "HP-",
        beforeCreatedAt: currentRegister(),
        demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
        statusId: `${orderSaleStatus}, ${orderTransferStatus}, ${orderSoldStatus}`,
      })
        .then((data) => {
          setCategoryList(data.response.content);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <motion.div
      className="row"
      initial={{ x: "50px", opacity: 0, scale: 0.5 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
    >
      <div className="col-md-6">
        <img
          src={mostLikedImage}
          alt="New Arrivals"
          className="w-100 rounded"
          tw="cursor-pointer"
          onClick={() => history.push("MostLiked")}
        />
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6 text-center">
            <h1 className="fw-bold" tw="md:m-0 my-5">
              Most Liked Product
            </h1>
            <p className=" ptSans">
              Our most loved designs deserve a worthy mention and here they are!
            </p>
            <TabControl
              key={1}
              active={false}
              onClick={() => history.push("MostLiked")}
              className="m-auto fw-bold w-75 text-center"
              tw="rounded-lg"
            >
              View All
            </TabControl>
          </div>
          <div className="col-md-6">
            <img
              src={Bangle}
              alt="New Arrivals"
              className="w-100"
              tw="rounded"
            />
          </div>
        </div>
        <Carousel
          enableAutoPlay
          autoPlaySpeed={1500} // same time
          itemsToShow={2}
          itemPadding={[5, 5]}
          className="newArrivalsComponent align-items-center pt-4"
        >
          {categoryList.map((data, index) => (
            <>
              <img
                src={
                  data.images === "@#@" ? Noimg : data.images.split("@#@")[0]
                }
                alt="Most Liked Product"
                className="rounded-circle border-solid border-1 border-custom-100"
                tw="cursor-pointer  h-48"
                onClick={() => history.push("MostLiked")}
                key={index}
              />
            </>
          ))}
        </Carousel>
      </div>
    </motion.div>
  );
}

export default memo(MostedLikedComponent);
