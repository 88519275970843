import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getBranchId, getHeaderConfig } from "utility/HeaderConfig";
import moment from "moment";
import { filterFormatToDate } from "utility/Constant";

const login = (requestBody: string) => {
  return axios
    .post(`${BASE_URL}/users/authenticate`, requestBody, {
      headers: { "Content-Type": "application/json", token: "" },
    })
    .then((response) => {
      let data = {};
      if (response.status === 200 && response != null) {
        data = response.data;
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
       throw error;
    });
};

const tokenN = getHeaderConfig()

const getUserAccount = async (userName) => {
  const responseData = await fetch(

    JSON.parse(await BASE_URL) + '/users/get-page?filters={"username":"'+userName+'"}',
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: tokenN

      }
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.content;
    })
    .catch((error) => console.error(error))
    .finally(() => {});
  return responseData;
};

const getCustomerLogin = async (mobile, isBranchFilerEnabled) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const branchId = getBranchId()

  let filterSearchValue = `mobile=${mobile}&beforeCreatedAt=${filterFormatToDate(currentDate)}&customerType=CUSTOMER`;
  if (isBranchFilerEnabled)
    filterSearchValue += `&branchId=${branchId}`;

  const responseData = await fetch(`${BASE_URL}/customers/autosearch?${filterSearchValue}`,
    {
      method: "GET",
      headers: {
        token: tokenN,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log("Customer Id Storage " + error);
    });
  return responseData;
};


export { login,getUserAccount, getCustomerLogin  };
