import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { getCarousel } from "api/CarouselApi";
import { filterFormatToDate } from "utility/Constant";
import moment from "moment";

const Container = tw.div`relative`;

export default () => {
  const [bannerList, setbannerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getbannerList();
    // eslint-disable-next-line
  }, []);

  const getbannerList = () => {
    let currentDate = moment().format("YYYY-MM-DD");
    setLoading(true);
    getCarousel({ offerType: "BANNERS", visibilityMode: "WEB_MOBILE", validOffersDate:filterFormatToDate(currentDate) }).then(
      (data) => {
        setbannerList(data.response.content);
        setLoading(false);
      }
    );
  };

  return (
    <Container>
      {loading && (
        <>
          <Skeleton style={{ height: "500px", width: "100%" }} />
          <div className="w-100 text-center d-flex justify-content-center">
            <Skeleton tw="h-20 w-20 text-center mx-3" />
            <Skeleton tw="h-20 w-20 text-center" />
          </div>
        </>
      )}
        <Carousel
          autoPlay
          infiniteLoop={true}
          emulateTouch={true}
          swipeable={true}
          interval={5000}
          transitionTime={1000}
          tw="text-center"
        >
          {bannerList.map((img, i) => (
            <div key={i}>
              <img alt="slider-images" src={img.offerImageString} />
            </div>
          ))}
        </Carousel>
    </Container>
  );
};
