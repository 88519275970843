import { useEffect, useState } from "react";
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";

const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

export default ({ configImages , branch }) => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const getBanner = () => {
      configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "privacyPolicy") {
          setBanner(data.value);
        }
      });
    };

    getBanner();
    //eslint-disable-next-line
  }, []);

  let  Site_Url = "https://vclive.prod-rasiinfotech";

  const list = [
    {
      title: `Privacy Policy`,
      description: `We understand that your privacy is of paramount importance to you, so your privacy is very important to us and ${branch.storeName} works to ensure that the users privacy is protected when using our service. ${branch.storeName} has a policy setting out how the personal information of user is processed and protected. Users can visit our website to find the revisions (if any) made to the user policy. ${branch.storeName} will not make any financial benefits by selling or leasing the user information to any third party.`,
      show: "show",
    },
    {
      title: `Information from User`,
      description: `Information from User`,
      show: "",
    },
    {
      title: `Information to be Utilized`,
      description: `The information provided by the user will be used to fulfill our commitment to the user and to provide sufficient information from our side benefiting them and for verifying their order and to communicate with them for getting information. This also includes letting the user know the latest offers that we think may interest you (by analyzing your personal information). If the user at any point of time wishes not to receive any such messages they may contact the support team by email and let them know their interest and decision. We keep and use their personal information as long as the user is interested in letting us use it or for as long as required by the law.`,
      show: "",
    },
    {
      title: `Information Sharing`,
      description: `${branch.storeName} will never share any of its website users personal information to any other company for spam or mass mailing or for marketing purposes. Payments and other financial transactions on the website are processed by a third party, which normally adheres to a strict policy regarding confidentiality agreement which limit their use of such information and are bound to the terms and conditions of the third party’s payment gateway process. However, details about financial transaction will never be stored or tracked by any of the system or software of ${Site_Url}. We have a non-disclosure agreement with this third party, and they are certified by all major card issuers to hold details securely.`,
      show: "",
    },
    {
      title: `Duration of Information Stored`,
      description:
        `The personal details of the user shall remain with us as long as the user requires it to serve the purpose of the data to exist with us or as long as required under law. Users may officially inform the support team that their data shall not be used by the ${branch.storeName} to communicate with them. We shall remove their personal data from the list for communication; however, it shall remain with us for any kind of legal verification as required by the Government of India.`,
      show: "",
    },
    {
      title: `Security about contents`,
      description: `We take appropriate precautions to protect the security of Personally Identifiable Information. We encrypt certain sensitive information using Secure Socket Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted to us. However, no data transmission over the Internet can be guaranteed to be completely secure. Accordingly, we cannot ensure or warrant the security of any information that you transmit to us, so you do so at your own risk. This Privacy Policy only addresses the use and disclosure of information we collect from you. Other websites that may be accessible through this Website have their own privacy policies and data collection, use and disclosure practices. If you link to any such website, we urge you review the website’s privacy policy. We are not responsible for the policies or practices of third party websites.`,
      show: "",
    },
    {
      title: `Securities for Transaction`,
      description: `Security for financial transactions is provided by the third party which can be checked on their terms and privacy policies. ${branch.storeName} shall not involve in any of the financial transactions made by the user. Any loss or problems due to financial transactions shall be inquired to the third party who provides the payment gateway and not to ${branch.storeName}. It is assumed that users are aware of the policies of using credit/debit card online or using internet banking while transacting with ${branch.storeName}.`,
      show: "",
    },
    {
      title: `Law and jurisdiction`,
      description: `This website and user agreement shall construe all applicable laws of India. We agree to use the Madurai jurisdiction and the courts of Madurai alone shall have exclusive jurisdiction on account of legal dispute. If any part of this website Disclaimer is found to be invalid by law, the rest of them remain valid and enforceable.`,
      show: "",
    },
    {
      title: `Contact & Personal Information`,
      description: `User has to enter their personal information such as Name, Address and Phone number. Personal information includes Date of Birth, which ${Site_Url} uses to verify the user’s age. After registration, user may change their personally identifiable information such as the login password in their profile by logging into their account and it is always advisable not to share your login details with anyone and to update the password at periodic intervals to keep it protected.`,
      show: "",
    },
    {
      title: `Modification Rights`,
      description: `${Site_Url} reserves every right to change or modify any of these terms and conditions or any policy, guidelines of the website without any prior notification to the users at any time on its sole discretion. Any change or modification will be effective immediately upon posting in the website. The changes in the terms and condition may be emailed to the users and it is assumed that the mailed content was read by the user after it was sent. Your continued use of the website following the posting of its changes or modifications will constitute your acceptance of such changes or modifications. Therefore, you should frequently review these Terms and Conditions and any other applicable policies from time-to-time to understand the terms and conditions that apply to your use of the website. The next time when the user logs in to the website after the change in terms and conditions it shall be assumed that the user agrees for the change in terms and condition. If you don’t accept the modified user agreement, you may discontinue using the service.`,
      show: "",
    },
    {
      title: `Disclaimer`,
      description: `The information contained in this website is for general information purposes only. Neither ${branch.storeName}, nor its directors, members, employees, agents, any third party, data or content provider shall be liable in any way to you or to any other third party, firm or corporation without limitation whatsoever for any loss, liability, damage (whether direct, indirect, special or consequential), personal injury, risk of harm or expense of any nature whatsoever arising from any delays in operation, inaccuracies, mistakes, errors in, deletion of files or email, defects, viruses, or any failure of performance, whether or not resulting from miraculous acts of God, communication failure, theft, destruction, or omission of any share price information ,or transmission thereof or for any actions taken in reliance thereon or occasioned thereby or by reason of non-performance or interruption, or termination thereof arising out of unauthorized access to the records, programs or services of ${branch.storeName} Limited, or in any way connected with the use of our website, the products and services, or the content contained in or accessed through our website.`,
      show: "",
    },
    {
      title: `Financial Transaction`,
      description: `We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on account of the cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.`,
      show: "",
    },
    {
      title: `Links and external Materials`,
      description: `The website may contain links to other websites; these external websites are not under our control. We cannot be held responsible for such websites and cannot make any guarantees about them. We provide these links because we think they might interest you, but we do not monitor or endorse these other Websites.`,
      show: "",
    },
    {
      title: `On Modification`,
      description: `We reserve the right to terminate or remove any of the services on website with immediate effect at any time, for example if there is a change in the law that limits our ability to provide the website.`,
      show: "",
    },
    {
      title: `Social Network`,
      description: `As per the current regulations of Government of India, all Jewels be it Gold, Silver, Diamond , Platinum or any other precious metal with or without precious or semi precious stones that are billed will fall within the scope of GST, which shall subsume the tax structure that had been in force till June 2017.`,
      show: "",
    },
    {
      title: `Liability`,
      description: `${branch.storeName} shall not take responsibility or liability for any loss or damage suffered by ordering online or through freight transit for the purchased product, except as required by law, even if it is argued that we could have foreseen the loss or the possibility of it brought to our attention.`,
      show: "",
    },
    {
      title: `Maintenance of the Website`,
      description: `Every effort is made to keep the website up and running smoothly. However, ${branch.storeName} takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.    `,
      show: "",
    },
    {
      title: `On Disclaimer`,
      description: `This website disclaimer covers No warranties, Limitations of liability, Exceptions, Reasonableness, Other parties, and Unenforceable provisions.This website disclaimer is governed by the law of India and we both agree to use the Madurai jurisdiction if there is any dispute between us. If any part of this website Disclaimer is found to be invalid by law, the rest of them remain valid and enforceable.`,
      show: "",
    },
  ];
  

  return (
    <div
      tw="relative font-display overflow-hidden"
      className="custom_background"
    >
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Privacy Policy" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <Heading>PRIVACY POLICY</Heading>
      <div className="container py-2 py-sm-5">
        <div className="accordion" id="accordionExample">
          {list.map((data, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
              <button
                  className={`accordion-button text-uppercase`}
                  //  ${
                  //   data.show === "" ? "collapsed" : "show"
                  // }`}
                  type="button"
                  data-bs-toggle="collapse"
                  // data-bs-target={`#one${index}`}
                >
                  {data.title}
                </button>
              </h2>
              <div
                id={`one${index}`}
                className={`accordion-collapse collapse ${
                  // data.show === "" ? "collapsed" :
                   "show"
                } ptSans`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>{data.description}</p>
                  <p>{data.description2}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
