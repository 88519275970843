import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";


const pincodeAutoSearch = (searchValue: string) => {
  return axios
    .get(`${BASE_URL}/admin/address/pincode/autosearch-code?searchString=${searchValue}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      throw error;
    });
};

const getPincodeHierarchy = (id: string) => {
  return axios
    .get(
      `${BASE_URL}/admin/address/pincode/get-hierarchy?id=${id}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const createAddress = (request) =>{
  return axios
      .post(`${BASE_URL}/delivery-address/create`, request, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
}

const updateAddress = (requestBody: Object) => {
  return axios
    .put(`${BASE_URL}/delivery-address/update`, requestBody, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getPageAddress = (size : Number , searchValue: Object) => {
  return axios
    .get(`${BASE_URL}/delivery-address/get-page?page=0&size=${size}&sort=createdAt,desc&filter=${JSON.stringify(
      searchValue
    )}`, getHeaderConfig())
    .then((dataList) => {
      const respData = {
        responseCode: dataList.status,
        response: dataList.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getAddress = (id: string) => {
  return axios
    .get(
      `${BASE_URL}/delivery-address/get?id=${id}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const deleteAddress = (id: String) => {
  return axios
    .delete(`${BASE_URL}/delivery-address/delete?id=${id}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getAllAddress = (filter:Object) => {
  return axios
    .get(
      `${BASE_URL}/delivery-address/get-all?filter=${JSON.stringify(
            filter
        )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

  export {
    pincodeAutoSearch ,
    getPincodeHierarchy,
    createAddress,
    updateAddress,
    getPageAddress,
    getAddress,
    deleteAddress,
    getAllAddress
  };