import axios from 'axios';
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';


const PurityApi = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/products/productrate/metadata/get-all?filters=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getTodayMetalRate = (searchValue) =>{
  return axios
  .get(
    `${BASE_URL}/products/productrate/metadata/get-page?sort=createdAt,desc&filters=${JSON.stringify(
      searchValue
    )}`,
    getHeaderConfig()
  )
  .then(response =>{
    const respData ={
      responseCode : response.status,
      response : response.data
    };
    return respData;
  })
  .catch(error => {
    console.log(error);
    throw error;
  })
}

export { PurityApi ,getTodayMetalRate };
