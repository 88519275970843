import React, { memo } from "react";
import LoginModal from "./LoginModal";
import ReginsterModal from "./RegisterModal";
import UserAccountModal from "./UserAccountModal";

function Modal({ register }) {
  if (register === "login") {
    return <LoginModal />;
  } else if (register === "register") {
    return <ReginsterModal />;
  } else if (register === "user") {
    return <UserAccountModal />;
  }
}

export default memo(Modal);
