import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

  const ECommerce = (filters: Object) => {
    return axios
      .get(`${BASE_URL}/admin/configuration/appconfig/get-config-data?moduleName=${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const getCcavenuePaymentLinkData = (requestBody) =>{
    return axios
      .put(`${BASE_URL}/accounts/onlinePayment/get-ccavenue-payment-link`,requestBody, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });

  }

  const getCcavenueOrderStatusData = (OrderId:Object) => {
    return axios
      .get(`${BASE_URL}/accounts/onlinePayment/get-ccavenue-order-status?orderId=${OrderId}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  export { ECommerce,getCcavenuePaymentLinkData,getCcavenueOrderStatusData };