import { useEffect, useState } from "react";
import tw from "twin.macro";
// import SliderImage from "react-zoom-slider";
import { ProductDetails, likeCount, wishCount } from "../api/ProducDetailsApi";
import { useLocation, useHistory, Link } from "react-router-dom";
import Noimg from "../images/no_img.jpg";
import { getProduct } from "api/ProductApi";
import { filterFormatToDate } from "utility/Constant";
import styled from "styled-components";
import {
  getAddToCart,
  getBranchId,
  getCustomerId,
  setAddToCart,
} from "../utility/HeaderConfig.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import toast, { Toaster } from "react-hot-toast";
// import Pincode from "components/Pincode";
import BookAppointmentButton from "components/BookAppointmentButton";
import ProductPriceDetails from "components/ProductPriceDetails";
import { useCartContext } from "context/UseCartContext";
import RelatedProduct from "components/RelatedProduct";
import Magnifier from "../components/Magnifier";
import { Carousel } from "react-responsive-carousel";
import { dayClose } from "api/NewSchemeApi";
import moment from "moment";

const StyledDiv = tw.div`font-display overflow-hidden md:overflow-visible `;

const AccordionButton = styled.button`
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.4s;

 &:hover {
    background-color: #ccc;
  }

  &.active {
    background-color: #ccc;
  }

`;

const Panel = styled.div`
  padding: 0 18px;
  background-color: white;
  display: ${(props) => (props.active ? 'block' : 'none')};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;

export default ({ configImages }) => {
  const [Details, setDetails] = useState([]);
  const [otherCharge,setOtherCharge] =useState([]);
  const [additionalCharge,setAdditionalCharge] =useState([]);
  const [isLoader, setLoader] = useState(false);
  const [Img, setImg] = useState([]);
  const [Spec, setSpec] = useState([]);
  const [LikeCount, setLikeCount] = useState("");
  const [WishCount, setWishCount] = useState("");
  const [banner, setBanner] = useState([]);
  const [currentImg, setCurrentImg] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isActiveToggle, setIsActiveToggle] = useState(false);
  const [secMetalDetail, setSecMetalDetail] =useState([]);

  // const [branchName, setBranchName ] = useState("");
  const history = useHistory();
  const location = useLocation();
  const myparam = location.state.detail
    ? location.state.detail
    : location.state;

  const { dispatch } = useCartContext();

  useEffect(() => {
    getProdDetails();
    getBanner();
    //eslint-disable-next-line
  }, [myparam]);

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "productDetails") {
          setBanner(data.value);
        }
      });
  };

  const dayCloseDay = (ProductDetails) => {
    dayClose({
      module: "Sales",
      branchId: getBranchId(),
    }).then((data) => {
      if (data.response.length > 0) {
        let dateString = data.response[0].activeDate;
        let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
        let dayCloseDate = date.format("YYYY-MM-DD");
        getRateApi(ProductDetails, dayCloseDate);
      }
    });
  };

  const getRateApi = async (ProductDetails, dayCloseDate) => {
    let allOtherCharges = [];
    let stoneChargeList = [];
    let secMetalDetails = [];
    let filter = {
      branch: getBranchId(),
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      id: ProductDetails.id,
    };
    dispatch({ type: "DAYCLOSED", payload: filterFormatToDate(dayCloseDate) });
    await getProduct(10, filter).then((data) => {
      let otherChargeJSON =JSON.parse(data.response.content[0].otherCharges);
      let secMetalJSON =JSON.parse(data.response.content[0].secMetalDetails);
      secMetalJSON.forEach((item)=>{
          secMetalDetails.push(item);
      });
      let datas = otherChargeJSON.filter((item) => {
        if(item.remarks === "stonecharge" ||
          item.remarks === "handlingcharge" ||
          item.remarks === "stampingcharge" ||
          item.remarks === "additionalcharge" ||
          item.remarks === "freightCharges")
          {
            return item;
          }
      });
      allOtherCharges = datas;
      setAdditionalCharge(allOtherCharges)
      otherChargeJSON.forEach((otherChargeItem) => {
        if({}.hasOwnProperty.call(otherChargeItem,"stone")){
          if (otherChargeItem.stone.length > 0) {
            stoneChargeList = otherChargeItem.stone;
          }
        }
      });
      let Rate = data.response.content[0];
      let ProductDetail = ProductDetails;
      // let BasePrice =
      //   Rate.basePrice +
      //   Rate.diamondTotal +
      //   Rate.gemTotal +
      //   Rate.hallmarkCharge;
      // let Certificate = JSON.parse(ProductDetail.otherCharges)[1].stone.length > 0 && JSON.parse(ProductDetail.otherCharges)[1].stone[0].certificatenumber ;
      let Certificate;
      JSON.parse(ProductDetail.otherCharges).map((value) => {
        if (value.stone && value.stone.length > 0) {
          Certificate = value.stone[0].certificatenumber;
        }
      });
      setDetails({ ...ProductDetail, ...Rate,
        //  ...BasePrice,
          Certificate});
      setOtherCharge(stoneChargeList);
      setSecMetalDetail(secMetalDetails);
    });
  };

  const getProdDetails = () => {
    setLoader(false);
    ProductDetails(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        let imageData = [];
        if (
          data.response.productImageUrlList !== null &&
          data.response.productImageUrlList !== "" &&
          data.response.productImageUrlList !== "-"
        ) {
          data.response.productImageUrlList.forEach((image) => {
            imageData.push({
              image: image,
            });
            setImg(imageData);
            setCurrentImg(data.response.productImageUrlList[0]);
          });
        } else {
          imageData.push({
            image: Noimg,
          });
          setImg(imageData);
          setCurrentImg(Noimg);
        }
        setSpec(data.response.prodSpecMappingList);
        setLoader(true);
        dayCloseDay(data.response);
      }
    );
    likeCount(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        setLikeCount(data.response > 0 ? data.response : 0);
      }
    );
    wishCount(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        setWishCount(data.response > 0 ? data.response : 0);
      }
    );
  };

  // const wish = (Wish) => {
  //   if (Wish) {
  //     setWishCount(WishCount - 1);
  //   } else {
  //     setWishCount(WishCount + 1);
  //   }
  // };

  // const like = (Like) => {
  //   if (Like) {
  //     setLikeCount(LikeCount - 1);
  //   } else {
  //     setLikeCount(LikeCount + 1);
  //   }
  // };

  const addToCart = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    products.push(Details);
    let storeCardItem = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    const validateProduct = storeCardItem.some(
      (item) => item.productCode === productCode
    );

    if (!validateProduct) {
      setAddToCart(JSON.stringify(products));
      // setTimeout(() => {
        dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
      // }, 0);
      toast.success("Product Added To The Cart", {
        duration: 2000,
        position: "top-right",
      });
    } else {
      toast.error("Product Already Added To The Cart !", {
        duration: 3000,
        position: "top-right",
      });
    }
  };

  const buynow = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    products.push(Details);
    let storeCardItem = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    const validateProduct = storeCardItem.some(
      (item) => item.productCode === productCode
    );
    if (!validateProduct) {
      if (getCustomerId()) {
        setAddToCart(JSON.stringify(products));
        dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
        history.push("/CheckoutPage", Details);
      } else {
        dispatch({ type: "MODAL", payload: "login" });
      }
    } else {
      if (getCustomerId()) {
        history.push("/CheckoutPage", Details);
      } else {
        dispatch({ type: "MODAL", payload: "login" });
      }
    }
  };

  const thumb = (index) => {
    setCurrentImg(Img[index].image);
  };

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  const toggleAccordionToggle = () => {
    setIsActiveToggle(!isActiveToggle);
  };

  return (
    <StyledDiv>
      <Toaster position="top-right" />
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Product Details" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          {Details.productName?.toLowerCase()}
        </span>
      </div>
      <section className="py-sm-5 py-3 position-relative" tw=" my-2 sm:my-10">
        <div className="container-lg">
          <div className="row">
            {isLoader ? (
              <>
                <div className="col-md-6">
                  <div className="position-static" tw="z-0">
                    <Magnifier
                      imgSrc={currentImg}
                      imgWidth={`100%`}
                      imgHeight={`100%`}
                      magnifierRadius={120}
                    />
                    <Carousel
                      emulateTouch={true}
                      swipeable={true}
                      interval={5000}
                      transitionTime={1000}
                      tw="text-center"
                      onClickThumb={thumb}
                      className="role"
                    >
                      {Img.map((data) => (
                        <div>
                          <img alt="slider-images" src={data.image} />
                        </div>
                      ))}
                    </Carousel>
                    {/* <SliderImage
                      data={Img}
                      showDescription={true}
                      direction="right"
                      width="100%"
                    /> */}
                  </div>
                </div>
                <div className="col-md-6" tw="sm:py-0 py-10">
                  <h2 tw="pb-2 uppercase">{Details.productName}</h2>
                  <div className="row">
                    <table tw="leading-9" className="col-md-12 col-lg-6">
                      <tr tw="mb-3">
                        <td tw="px-3"> Product Code</td>:
                        <td tw="px-3">{Details.productCode}</td>
                      </tr>
                      <tr>
                        <td tw="px-3">Product Type</td>:
                        <td tw="px-3">
                          {Details.productTypeName}
                          <div className="badge mx-2" tw="bg-yellow-600 p-1">
                            {" "}
                            {Details.purityName}
                          </div>
                        </td>
                      </tr>
                      <tr>
                      <td tw="px-3">hallmark</td>:
                      <td tw="px-3"> {Details.hallmarked ? Details.hallmarked : "-" }</td>
                      </tr>
                      {Details.brand !== ""? 
                      <tr>
                        <td tw="px-3">Brand</td>:
                        <td tw="px-3">{ Details.brand}</td>
                      </tr> : null
                         }
                      {Details.huId !== null &&
                      Details.huId !== "-" &&
                      Details.huId !== "" ? (
                        <tr>
                          <td tw="px-3">Huid</td>:
                          <td tw="px-3">{Details.huId}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </table>
                    <div className="col-md-6">
                      <div tw="mt-4 md:mt-0 lg:mt-4">
                        <i className="bi bi-heart"></i> {WishCount} Wish
                        <i className="bi bi-hand-thumbs-up ms-3"></i>{" "}
                        {LikeCount} Like
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-5">
                      <div className="py-2">
                        <h3 className="price ">
                          <span tw="text-base">Price :</span> ₹{" "}
                          {Math.trunc(Details.total)}
                        </h3>
                        <small>*Inclusive of all Details</small>
                      </div>
                      {Details.status === "Sale" ? 
                      <div tw="text-green-600 py-1">
                      <h6 tw="mb-1">
                        <span>Available at {Details.branchName} Branch</span>
                      </h6>
                      {/* <i className="bi bi-shop" tw="text-xl font-semibold"></i> <a tw="text-green-600  text-sm mr-1 cursor-pointer">Choose Your</a><span tw="text-black text-sm"> Nearest Branch</span>                        */}
                    </div>
                    :null}
                      {Details.Certificate > 0 && (
                        <p tw="text-sm my-2">
                          Certificate No : {Details.Certificate}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6  col-lg-6 col-xl-7">
                      <h6 tw="text-custom-100  mt-3 mb-3">
                        Weight Details (gms)
                      </h6>
                      <table className="table table-bordered mb-2">
                        <thead>
                          <tr>
                            <th>G.WT</th>
                            <th>L.WT</th>
                            <th>N.WT</th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0 ">
                          <tr>
                            <td>{Details.grossWeight}</td>
                            <td>{Details.lessWeight}</td>
                            <td>{Details.netWeight}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p tw="text-sm  leading-7" className="line-clamp ">
                    {Details.description !== null &&
                    Details.description !== "-" &&
                    Details.description !== ""
                      ? Details.description
                      : ""}
                  </p>
                  {Details.status !== "Sale" ? 
                  <p tw="text-sm  leading-7" className="line-clamp ">
                  <span style={{color : 'red'}}> <i class="bi bi-exclamation-circle"></i> {" "}Temporarily Out of Stock :</span>{" "}
                  You can still order this !
                 </p>
                  :null }
                  {Details.pieceRate && Details.pieceRate !== "" ? 
                  <div className="row">
                    <div className="col mb-3">
                       Pcs Rate : <span>{Details.pieceRate}</span>
                    </div>
                     </div>
                    :null}
                  {Details.wastageCost && Details.wastageInPer && Details.mcValue && Details.mcType !== '' ?
                       <div className="container">
                       <div className="row">
                        <div className="col">
                          <b>Wastage</b>
                        </div>
                        <div className="col">
                          <b>Mc</b>
                        </div>
                       </div>
                       <div className="row">
                        <div className="col">
                          <p>
                            {/* {Details.wastageCost && Details.wastageInPer} % */}
                            
                          {`( ${Details.wastageValue}${Details.wastageType === "percentage"
                          ? "%"
                          : Details.wastageType === "pieces"
                            ? "pcs"
                            : Details.wastageType === "rs"
                              ? "rs"
                              : Details.wastageType === "mg"
                                ? "gm"
                                : Details.wastageType
                          } )`}{" "}
                          </p>
                        </div>
                        <div className="col">
                          <p>₹ {`${Details.mcValue} - ${Details.mcType === "percentage"?"%":  Details.mcType === "pieces"
                        ? "pcs"
                        : Details.mcType === "rs"
                          ? "rs"
                          : Details.mcType === "mg"
                            ? "gm"
                            : Details.mcType}`}</p>
                        </div>
                       </div>
                       </div>
                      :null}
                  {additionalCharge && additionalCharge.length > 0 ? 
                  <>
                     <AccordionButton onClick={toggleAccordion} className={isActive ? 'active' : ''}>
                       Additional Charges
                     </AccordionButton>
                     <Panel active={isActive}>
                      <div className="d-flex justify-content-center mt-3">
                        <h5> {(additionalCharge && additionalCharge.length > 0 ?additionalCharge[0].remarks:"").toUpperCase()} </h5>
                      </div>
                        <hr style={{backgroundColor:"red", height:'2px' ,margin:'0px'}}/>
                           <div className="container">
                            <div className="row">
                                <div className="col">
                                  <b>Charge Type</b>
                                </div>
                                <div className="col">
                                  <b>Piece</b>
                                </div>
                                <div className="col">
                                <b>Charge</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                 <p className="w-50"> {additionalCharge && additionalCharge.length > 0 ?additionalCharge[0].type : ""}</p>
                                </div>
                                <div className="col">
                                 <p className="w-50"> {additionalCharge && additionalCharge.length > 0 ?additionalCharge[0].piece :""}</p>
                                </div>
                                <div className="col">
                                  <p className="w-50">{additionalCharge && additionalCharge.length > 0 ?additionalCharge[0].charge : ""}</p>
                                </div>
                            </div>
                           </div>
                     </Panel>
                     </>:null}
                     {secMetalDetail && secMetalDetail.length > 0 ? 
                  <>
                     <AccordionButton onClick={toggleAccordionToggle} className={isActiveToggle ? 'active' : ''}>
                       Secondary Metal
                     </AccordionButton>
                     <Panel active={isActiveToggle}>
                      <div className="d-flex justify-content-center mt-3">
                        <h5> {secMetalDetail && secMetalDetail.length > 0 ?secMetalDetail[0].remarks:""} </h5>
                      </div>
                           <div className="container">
                            <div className="row">
                                <div className="col">
                                 <b> Metal Type :</b>{" "}<span>{secMetalDetail && secMetalDetail.length > 0 ?secMetalDetail[0].productTypeName : ""}</span>
                                </div>
                                <div className="col">
                                 <b> Purity : </b>{" "}<span>{secMetalDetail && secMetalDetail.length > 0 ?secMetalDetail[0].purityName :""}</span>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col">
                                <b>  Halmarked :</b>{" "}<span>{secMetalDetail && secMetalDetail.length > 0 ?secMetalDetail[0].standardName : ""}</span>
                                </div>
                                <div className="col">
                                 <b> Piece :</b>{" "}<span>{secMetalDetail && secMetalDetail.length > 0 ?secMetalDetail[0].piece : ""}</span>
                                </div>
                            </div>
                            <div className="row">
                              <div className="col"> 
                              <b>Net Weight : </b>{" "}<span>{secMetalDetail && secMetalDetail.length > 0 ? secMetalDetail[0].weight.netWeight:""}</span>
                              </div>
                            </div>
                           </div>
                     </Panel>
                     </>:null}
                     {otherCharge && otherCharge.length > 0 ? 
                     <>
                      <div className="mt-2">
                        <h4> Stone Details :</h4>
                        <hr style={{backgroundColor:"red", height:'2px'}}/>
                        <div className="d-flex justify-content-between">
                          <p className="w-50">Stone Type </p>
                           <p className="w-50"> 
                            {otherCharge && otherCharge.length > 0 ? otherCharge[0].typeName : ""}
                            </p>
                           </div>
                           {otherCharge && otherCharge[0].dataName !== "" ? 
                           <div className="d-flex justify-content-between">
                           <p className="w-50">Material Type</p>
                            <p className="w-50"> 
                            {otherCharge && otherCharge.length > 0 ? otherCharge[0].dataName : ""}
                            </p>
                           </div>
                           :null}
                          
                           {otherCharge && otherCharge[0].pieces !== "" && otherCharge[0].pieces !== 0 ? 
                           <div className="d-flex justify-content-between">
                           <p className="w-50">Pieces</p>
                            <p className="w-50"> 
                            {otherCharge && otherCharge.length > 0 ? otherCharge[0].pieces : ""}
                            </p>
                           </div>
                           :null}
                           <div className="d-flex justify-content-between">
                           <p className="w-50">Cost</p>
                            <p className="w-50"> 
                            {otherCharge && otherCharge.length > 0 ? otherCharge[0].cost : ""}
                            </p>
                           </div>
                           <div className="d-flex justify-content-between">
                           <p className="w-50">Charge Type</p>
                            <p className="w-50"> 
                            {otherCharge && otherCharge.length > 0 ? otherCharge[0].rateType : ""}
                            </p>
                           </div>
                           {otherCharge && otherCharge.map( item => (
                               <>
                               {/* {console.log("item.stoneDescription",item.stoneDescription)} */}
                           <div className="d-flex justify-content-between">
                           {otherCharge && item.stoneDescription.length > 0 ?
                           <>
                           <p className="w-50">Clarity</p>
                            <p className="w-50"> 
                            {item.stoneDescription[0].value} 
                            </p>
                            </>
                            :null
                            }
                           </div>
                           <div className="d-flex justify-content-between">
                           {otherCharge && item.stoneDescription.length > 0 ?
                           <>
                           <p className="w-50">Shape</p>
                            <p className="w-50"> 
                             {item.stoneDescription[1].value}
                            </p>
                           </> :null}
                           </div>
                           <div className="d-flex justify-content-between">
                           {otherCharge && item.stoneDescription.length > 0 ?
                           <>
                            <p className="w-50">Cut</p>
                            <p className="w-50"> 
                             {item.stoneDescription[2].value}
                            </p>
                           </> :null}
                           </div>
                             </>
                           ))}
                      </div>
                      </>
                      : null}
                  <div className="row">
                    {Spec.length > 0 && (
                      <div className="col-md-4">
                        <h6 tw="text-custom-100  mb-5">
                          Specification Details
                        </h6>

                        <table tw="leading-9" className="col-md-6 w-100">
                          {Spec.map((data, index) => (
                            <tr tw="mb-3" key={index}>
                              <td tw="px-3">
                                {data.specificationName.split("_")[0]}{" "}
                              </td>{" "}
                              :
                              <td tw="px-3">
                                {data.specificationValue !== "NA"
                                  ? data.specificationValue
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    )}
                    <div
                      className={`${
                        Spec.length > 0 ? "col-md-8" : "col-md-12"
                      }`}
                    >
                      <ProductPriceDetails Details={Details}  secMetalDetail={secMetalDetail}/>
                    </div>
                  </div>
                  <div className="row py-4">
                    {Details.status !=="Sale" ?
                    <BookAppointmentButton category={Details} />
                    :
                    <>
                    {/* <Pincode /> */}
                    <div className=" col-12 col-lg-4 my-lg-0 my-3">
                      <div id="holder">
                        <div
                          className="button"
                          onClick={() => addToCart(Details.productCode)}
                        >
                          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                            Add To Cart
                          </p>
                          <div className="btnTwo">
                            <p className="btnText2">
                              <i className="bi bi-cart-plus"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-12 col-lg-4">
                      <div id="holder">
                        <div
                          className="button"
                          onClick={() => buynow(Details.productCode)}
                        >
                          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                            Buy now
                          </p>
                          <div className="btnTwo">
                            <p className="btnText2">
                              <i className="bi bi-cash"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
                    }   
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6">
                  <Skeleton tw="w-full h-full" />
                </div>
                <div className="col-md-6">
                  <Skeleton tw="pb-2 uppercase w-3/4 sm:h-1/12 h-0 mt-2 mb-0" />
                  <div className="row mx-2">
                    <table tw="leading-9" className="col-md-6">
                      <tr tw="mb-3">
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                    </table>
                    <div className="col-md-6">
                      <div tw="mt-1">
                        <Skeleton tw="w-5/12" />
                        <Skeleton tw="w-5/12 my-4" />
                        <Skeleton tw="w-5/12" />
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div tw="mt-1">
                      <Skeleton tw="uppercase w-3/4 " />
                    </div>
                  </div>
                  <div tw="text-green-600 py-1">
                    <h6>
                      <Skeleton tw="w-5/12" />
                    </h6>
                  </div>
                  <p tw="text-sm">
                    <Skeleton tw="w-6/12" />
                  </p>
                  <Skeleton tw="w-4/12 my-3" />
                  <table className="table table-bordered">
                    <Skeleton count={3} tw="w-3/4" />
                  </table>
                  <div className="row">
                    <Skeleton count={3} tw="w-2/4" />
                  </div>
                  <Skeleton tw="w-1/4 h-1/12 mb-20" />
                </div>
              </>
            )}
          </div>
          <a
            href={`https://api.whatsapp.com/send?phone="+91 09629157099"&text=I am intrested to buy this product * Tag No : ${Details.productCode} , Branch - ${Details.branchName}`}
            className="scroll-to-top position-fixed  bottom-0 end-0 "
            tw="mx-6 my-20"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="rounded-circle text-white p-1"
              style={{ cursor: "pointer", backgroundColor: "#25d366" }}
            >
              <i
                className="bi bi-whatsapp px-2 fs-2"
                style={{ fontSize: "11px" }}
              ></i>
            </div>
          </a>
        </div>
      </section>
      <RelatedProduct Details={Details} />
    </StyledDiv>
  );
};
