import axios from "axios";
import { BASE_URL } from "utility/UrlConstants";
import { getHeaderConfig } from "utility/HeaderConfig";

const getAllSale = (filters)=>{
   return axios.get(`${BASE_URL}/salesestimation/sales/addsales/get-all?salesFilter=${JSON.stringify(filters)}`, getHeaderConfig()).then((response)=>{
        let respData = {
            responseCode : response.status,
            response : response.data
        };
        return respData;
   }).catch((err)=>{
      throw err
   })
}


const downloadFile = async (url, filename) => {
  try {
    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // Important
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
    // Handle error as needed
  }
};

const invoiceApi = (id) =>{
   return axios
       .post(`${BASE_URL}/salesestimation/sales/addsales/export-pdf-invoice?filter=${id}`, getHeaderConfig())
       .then(response => {
         const respData = {
           responseCode: response.status,
           response: response.data
         };
         return respData;
       })
       .catch(error => {
         console.log(error);
         throw error;
       });
 }

export { getAllSale,invoiceApi,downloadFile }
