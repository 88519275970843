import tw from "twin.macro";
import styled from "styled-components";

const TabsControl = tw.div` bg-custom-200 px-2 py-2 rounded leading-none my-0 relative`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:(mr-2 mt-2 w-auto text-sm) md:m-0 last:mr-0 text-black font-medium rounded-sm transition duration-300 text-xs w-1/2 text-center uppercase`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

export default (props) => {
  return (
    <TabsControl>
      <div className="row">
        <div className="col-md-6">
          <TabControl
            active={props.states ? true : false}
            className="col-md-6 w-100 border rounded"
            onClick={props.details}
          >
            Payment Details
          </TabControl>
        </div>
        <div className="col-md-6">
          <TabControl
            active={props.states ? false : true}
            className="col-md-6 w-100 border rounded"
            onClick={props.onlineTnx}
          >
           Online Txn Details
          </TabControl>
        </div>
      </div>
    </TabsControl>
  );
};
