import tw from "twin.macro";
import Carousel from "./components/Carousel.js";
import ProductCategory from "./components/ProductCategory.js";
import GenderBased from "./components/GenderBasedProduct.js";
import ProductType from "./components/ProductType";
import ArrivalsLiked from "components/ArrivalsLiked.js";
import SocialMedia from "components/SocialMedia.js";
import BestSelling from "components/BestSelling.js";
import SecondBanner from "components/SecondBanner.js";
import TrendOnShops from "components/TrendOnShops.js";
import ShopOurExclusives from "components/ShopOurExclusives.js";
import OccationCategories from "components/OccasionCategories.js";
const Container = tw.div`relative overflow-hidden`;
export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */



  
  return (
    <Container>
      <Carousel />
      <ProductType />
      <SecondBanner/>
      <ProductCategory />
      <BestSelling />
      <TrendOnShops/>
      <ShopOurExclusives/>
      <OccationCategories/>
      <ArrivalsLiked />
      <GenderBased />
      <SocialMedia />
    </Container>
  );
};
