import { getBranchId } from "./HeaderConfig";
import { COINS_NAME, COIN_NAME, DIAMOND_PRODUCT_TYPE } from "./Constant";
import { createSales } from "api/CheckOutApi";

const createSaleItems = async (
  estimationId,
  CartItem,
  Details,
  paymentModeList,
  defaultFinalFinancialYr,
  ledgerNameDetails,
  onlinePaymentEmplyId,
  soldOrderStatusId,
  invoiceExCategoryId,
  saleDayCloseDate,
  estiDayCloseDate,
  estiOrderStatusId,
  estiCompOrderStatusId
) => {
  const saleItemsList = [];
  const taxJsonValue = [];
  const productDetailsJson = CartItem[0];
  productDetailsJson.saleTaxList.forEach((taxItem) => {
    taxJsonValue.push({
      [taxItem.name]: taxItem.value,
    });
  });

  const taxDetailsForReceipt = [];
  const paymentModeJson = [];
  const ledgerDetails = [];
  // let selectedPaymentMode = "";

  taxDetailsForReceipt.push({ tax: taxJsonValue });
  taxDetailsForReceipt.push({ module: "Sale" });

  const totalSaleAmtValue = CartItem.reduce((sum, i) => {
    return sum + i.total;
  }, 0);

  const filteredPaymodeData = [];
  const cashPaymodeData = paymentModeList.filter(
    (paymodeItemData) =>
      paymodeItemData.name && paymodeItemData.name.toLowerCase() === "cash"
  );
  paymentModeJson.push({
    tax: taxJsonValue,
    name:
      filteredPaymodeData.length > 0
        ? filteredPaymodeData[0].name
        : cashPaymodeData[0].name,
    value: totalSaleAmtValue.toFixed(2),
    remarks: `${totalSaleAmtValue.toFixed(2)} rcvd via app`,
    paymentType:
      filteredPaymodeData.length > 0
        ? filteredPaymodeData[0].id
        : cashPaymodeData[0].id,
  });

  await CartItem.forEach((item) => {
    const productFullDetails = item;
    const wastageType = productFullDetails.wastageType;
    const mcType = productFullDetails.mcType;
    const wastageValue = productFullDetails.wastageValue;
    const basePrice = productFullDetails.basePrice;
    const netWeightMC = productFullDetails.netMc;
    const secMetalDetails = productFullDetails.secMetalDetails;
    const mcValue = productFullDetails.mcValue;
    const otherChargeData = JSON.parse(productFullDetails.otherCharges);

    let saleWastageType = "flat";
    let saleMCType = "flat";
    let saleWastageUnit = "percentage";
    let saleMCUnit = "rs";

    if (wastageType === "mg") {
      saleWastageUnit = "mg";
      saleWastageType = "pergm";
    } else if (wastageType === "rs") {
      saleWastageUnit = "rs";
      saleWastageType = "flat";
    } else if (wastageType === "percentage") {
      saleWastageUnit = "percentage";
      saleWastageType = "flat";
    } else if (wastageType === "pieces") {
      saleWastageUnit = "pieces";
      saleWastageType = "flat";
    }

    if (mcType === "mg") {
      saleMCType = "pergm";
      saleMCUnit = "rs";
    } else if (mcType === "rs") {
      saleMCType = "flat";
      saleMCUnit = "rs";
    } else if (mcType === "percentage") {
      saleMCType = "flat";
      saleMCUnit = "percentage";
    } else if (mcType === "pieces") {
      saleMCType = "flat";
      saleMCUnit = "pieces";
    }

    let mcGramType = "";
    let mcGramUnit = "";
    let mcRateType = "";
    let mcRateUnit = "";
    let saleMcAmount = 0;
    let saleMcWeight = 0;
    if (saleMCType === "pergm") {
      mcGramType = saleMCType;
      mcGramUnit = saleMCUnit;
      saleMcWeight = productFullDetails.mcValue;
    } else {
      mcRateType = saleMCType;
      mcRateUnit = saleMCUnit;
      saleMcAmount = productFullDetails.mcValue;
    }

    const productWeight = JSON.parse(productFullDetails.weight);
    const isCoinPresent = productFullDetails.productName.includes(
      COIN_NAME || COINS_NAME
    );

    let stoneChargeList = [];
    const stoneChargeDetails = [];
    const otherChargeDetails = [];

    otherChargeData.forEach((otherChargeItem) => {
      if ({}.hasOwnProperty.call(otherChargeItem, "stone")) {
        if (otherChargeItem.stone.length > 0) {
          stoneChargeList = otherChargeItem.stone;
        }
      }
    });

    stoneChargeList.forEach((stoneItem) => {
      let shape = "";
      let cutType = "";
      let clarity = "";
      let color = "";
      let purity = "";
      if (stoneItem.stoneDescription) {
        stoneItem.stoneDescription.forEach((stoneDescRes) => {
          if (stoneDescRes.key === "shape") {
            shape = stoneDescRes.value;
          } else if (stoneDescRes.key === "cut") {
            cutType = stoneDescRes.value;
          } else if (stoneDescRes.key === "clarity") {
            clarity = stoneDescRes.value;
          } else if (stoneDescRes.key === "color") {
            color = stoneDescRes.value;
          } else if (stoneDescRes.key === "purity") {
            purity = stoneDescRes.value;
          }
        });
      }

      const stoneDetailsArray = [];
      const stoneMetaPurityValue = {
        key: "purity",
        value: purity ? purity : "",
      };
      const stoneMetaColorValue = {
        key: "color",
        value: color ? color : "",
      };
      const stoneMetaClarityValue = {
        key: "clarity",
        value: clarity ? clarity : "",
      };
      const stoneMetaShapeValue = {
        key: "shape",
        value: clarity ? shape : "",
      };
      const stoneMetaCutUnCutValue = {
        key: "cut",
        value: cutType ? cutType : "",
      };
      stoneDetailsArray.push(
        stoneMetaPurityValue,
        stoneMetaColorValue,
        stoneMetaClarityValue,
        stoneMetaShapeValue,
        stoneMetaCutUnCutValue
      );
      stoneChargeDetails.push({
        grm: stoneItem.grm,
        cost:
          productFullDetails.pieceRate > 0 &&
          (stoneItem.dataName === undefined ||
            (stoneItem.dataName !== undefined &&
              stoneItem.dataName.toLowerCase() !== DIAMOND_PRODUCT_TYPE))
            ? 0
            : stoneItem.cost,
        remark: stoneItem.remark,
        typeId: stoneItem.typeId,
        typeName: stoneItem.typeName,
        rateType: stoneItem.rateType,
        pieces: stoneItem.pieces,
        size: stoneItem.size ? stoneItem.size : "",
        stoneDescription: stoneDetailsArray,
        materialType: stoneItem.materialType ? stoneItem.materialType : "",
      });
    });
    otherChargeDetails.push({ stone: stoneChargeDetails });

    otherChargeDetails.push({
      piece: 0,
      charge: productFullDetails ? productFullDetails.otherCharge : 0,
      type: "",
      remarks: "TotalOtherChargeAmt",
    });

    const secWeightDetails = [];
    const primaryTaxTotal = productFullDetails.saleTaxList.reduce((sum, i) => {
      return sum + i.amount;
    }, 0);
    JSON.parse(secMetalDetails).forEach((secItem) => {
      let saleSecWastageType = "flat";
      let saleSecMCType = "flat";
      let saleSecWastageUnit = "percentage";
      let saleSecMCUnit = "rs";
      if (secItem.wastageType === "mg") {
        saleSecWastageUnit = "mg";
        saleSecWastageType = "pergm";
      } else if (secItem.wastageType === "rs") {
        saleSecWastageUnit = "rs";
        saleSecWastageType = "flat";
      } else if (secItem.wastageType === "percentage") {
        saleSecWastageUnit = "percentage";
        saleSecWastageType = "flat";
      } else if (secItem.wastageType === "pieces") {
        saleSecWastageUnit = "pieces";
        saleSecWastageType = "flat";
      }

      if (secItem.mcType === "mg") {
        saleSecMCType = "pergm";
        saleSecMCUnit = "rs";
      } else if (secItem.mcType === "rs") {
        saleSecMCType = "flat";
        saleSecMCUnit = "rs";
      } else if (secItem.mcType === "percentage") {
        saleSecMCType = "flat";
        saleSecMCUnit = "percentage";
      } else if (secItem.mcType === "pieces") {
        saleSecMCType = "flat";
        saleSecMCUnit = "pieces";
      }

      let secSaleMcWeight = null;
      let secSaleMcAmount = null;

      if (saleSecMCType === "pergm") {
        secSaleMcWeight = secItem.mcValue;
      } else {
        secSaleMcAmount = secItem.mcValue;
      }
      secWeightDetails.push({
        // secTax: secItem.tax,
        secTax: 0,
        weight: {
          netWeight: parseFloat(secItem.weight.netWeight).toString(),
          lessWeight: parseFloat(secItem.weight.lessWeight).toString(),
          grossWeight: parseFloat(secItem.weight.grossWeight).toString(),
        },
        secName: secItem.product_name,
        // secRate: secItem.price,
        secRate: 0,
        wastageValue: secItem.wastage,
        wastageUnit: saleSecWastageUnit,
        wastageType: saleSecWastageType,
        mcValue: secItem.mcValue,
        mcUnit: saleSecMCUnit,
        mcType: saleSecMCType,
        secPiece: 1,
        // secTotal: secItem.total,
        secTotal: 0,
        mc_weight: secSaleMcWeight,
        mc_amount: secSaleMcAmount,
        secBasePrice: 0,
        // secBasePrice: secItem.basePrice,
        secProductType: secItem.productType,
        secProductTypeName: secItem.productTypeName,
      });
    });

    const salesWeight = {
      grossWeight: productWeight.grossWeight,
      lessWeight: productWeight.lessWeight,
      netWeight: parseFloat(productWeight.netWeight).toString(),
      wastageValue: parseFloat(wastageValue).toString(),
      wastageAmt: "0.000",
      mcValue: parseFloat(mcValue).toString(),
      balanceLess: "0.00",
      balanceNet: "0.00",
      balanceGross: "0.00",
      manipulationData: {
        mcGramType,
        mcGramUnit,
        mcRateType,
        mcRateUnit,
        wastageType: saleWastageType,
        wastageUnit: saleWastageUnit,
        mcType: saleMCType,
        mcUnit: saleMCUnit,
      },
      isDiscountEnable: false,
      isSp: false,
      isChitWtAdjustEnable: false,
      totalDisbmtGrams: "0.00",
      actualBasePrice: basePrice,
      actualMakingCharges: parseFloat(netWeightMC).toString(),
      actualNetWt: parseFloat(productWeight.netWeight).toString(),
      actualGrossWt: parseFloat(productWeight.grossWeight).toString(),
      chitAdjustAfterBalNwt: "0.00",
      chitAdjustAfterBalGwt: "0.00",
      adjustedChitWt: "0.00",
      actualWastage: parseFloat(wastageValue).toString(),
      chitNetWeight: "0.00",
      chitAdjustBalanceWt: "0.00",
      chitAdjustType: "",
      finalBasePrice: parseFloat(basePrice).toFixed(2),
      actualMC: parseFloat(netWeightMC).toString(),
      finalMC: parseFloat(netWeightMC).toString(),
      actualWst: parseFloat(wastageValue).toString(),
      finalWstVal: parseFloat(wastageValue).toString(),
      actualTax: parseFloat(primaryTaxTotal).toFixed(2),
      finalTax: parseFloat(primaryTaxTotal).toFixed(2),
      actualWstGrm: "0.00",
      acutualMcGrm: "0.00",
      finalMcGrm: "0.00",
      finalWstGrm: "0.00",
      mcBenefit: "0.00",
      wstBenefit: "0.00",
      chitWtAdjustValue: "0.00",
      chitMcValue: "0.00",
      chitWastageValue: "0.00",
      discountAdjsutWstVal: "0.00",
      discountAdjustTax: "0.00",
      discountAdjsutMcVal: "0.00",
      mcAmt: parseFloat(netWeightMC).toFixed(2),
    };

    // debugger
    saleItemsList.push({
      pieceRate: parseFloat(productFullDetails.pieceRate),
      pdtAdvItemId: null,
      additionalCharges: JSON.stringify(otherChargeDetails),
      basePrice: productFullDetails.basePrice,
      discount: 0,
      price: isCoinPresent
        ? productFullDetails.todayCoinRate
        : productFullDetails.todayRate,        
      hallmarkCharge: productFullDetails.hallmarkCharge,
      hmTaxPercentage: productFullDetails.hmTaxPercentage,
      productId: productFullDetails.id,
      saleMcAmount,
      saleMcWeight,
      saleSecondaryWeight: JSON.stringify(secWeightDetails),
      saleWastage: item.wastageValue,
      salesWeight: JSON.stringify(salesWeight),
      soldBy: onlinePaymentEmplyId,
      deliveryStatus: "No",
      status: soldOrderStatusId,
      tax: primaryTaxTotal.toFixed(2),
      total: item.total,
      estimation: null,
      piece: productFullDetails.piece,
      hasPartlySale: "No",
      productTagNo: productFullDetails.productCode,
      productName: productFullDetails.productName,
      availableWeightAfterProcess: 0,
      processChangeDate: null,
      combinedProducts: null,
    });
  });

  const receiptData = [];
  receiptData.push({
    accountEntryType: "CREDIT",
    entryType: "RECEIVABLES",
    financialYear: defaultFinalFinancialYr,
    balanceAmount: 0,
    bankAccount: null,
    branch: getBranchId(),
    category: invoiceExCategoryId, // Need id
    customerId: Details.customerId,
    customerMobile: Details.mobile,
    customerName: Details.name,
    invoice: null,
    nextPaidDate: null,
    paidAmount: totalSaleAmtValue,
    payableAmount: totalSaleAmtValue,
    paymentMode: JSON.stringify(paymentModeJson),
    status: "paid",
    taxComponent: JSON.stringify(taxDetailsForReceipt),
    totalAmount: totalSaleAmtValue,
    receiptType: "Sale_receipt",
    employeeId: onlinePaymentEmplyId,
    dayCloseModuleName: "sales",
  });
  const saleDetails = {
    branchId: getBranchId(),
    counterNo: null,
    exchangeAmount: 0,
    paymentMode: JSON.stringify(paymentModeJson),
    saleItems: saleItemsList,
    soldTo: {
      id: Details.customerId,
      mobile: Details.mobile,
      name: Details.name,
    },
    receiptList: receiptData,
    status: soldOrderStatusId, // Need to pass status id
    paymentType: "Advance_Without_Delivery",
    totalAmt: parseFloat(totalSaleAmtValue).toFixed(2),
    totalAmtWithoutDiscount: parseFloat(totalSaleAmtValue).toFixed(2),
    discountAmt: 0,
    reference: "N/A",
    remarks: "",
    // estimationId: estimationId,
    exchange: null,
    estimationExchangeId: [],
    expenses: null,
    customerCreditDetails: null,
    chitDisbursement: null,
    estimation: estimationId,
    returnDetail: JSON.stringify([]),
    receivedAmount: parseFloat(totalSaleAmtValue).toFixed(2),
    returnChange: 0,
    customerCurrentCredit: 0, // Need to pass customer current credit value
    customerCurrentDebit: 0,
    customerCreditUsage: [],
    custPrevCredit: Details.customerCurrentCredit, // need to check
    custPrevDebit: Details.customerCurrentDebit, // need to check
    custPayableAmount: 0,
    businessType: "B2C",
    tcsDetails: null,
    currencyCode: "INR", // check with nk sir
    ledgerDetailsDomainList: null,
    deleteLedgerDetailsIdsList: [],
    customerType: "CUSTOMER",
    enquiryId: null,
    source: "ECOM_MOBILE",
    deletePaymentMode: [],
    newPaymentMode: [],
  };


  try {
    const result = await createSales(saleDetails);
    return result;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to propagate it to the caller
  }
};

export { createSaleItems };
