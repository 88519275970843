import tw from "twin.macro";
import { useCartContext } from "context/UseCartContext";
import React, { memo } from "react";
import Modal from "./Modal";

//eslint-disable-next-line
const Heading = tw.h1`text-black`;

 function OpenModal() {
  const { modal } = useCartContext();
  return (
    <>
      {modal && <><Modal register={modal} /> <div tw="fixed inset-0 z-40" className="bg-dark bg-opacity-50"></div></>}
      
    </>
  );
}

export default memo(OpenModal)
