import React, { useState } from 'react';
import http from 'http';
import qs from 'querystring';
import { decrypt } from './ccavutil';

function CCAvResponseHandler() {
  const [responseData, setResponseData] = useState('');

  function handleResponse(request, response) {
    let ccavEncResponse = '';
    let ccavResponse = '';
    const workingKey = '40187F7FFE7EA2CF83B2189F232AAA8E'; // Put in the 32-Bit key provided by CCAvenues.
    let ccavPOST = '';
    request.on('data', function (data) {
      ccavEncResponse += data;
      ccavPOST = qs.parse(ccavEncResponse);
      const encryption = ccavPOST.encResp;
      ccavResponse = decrypt(encryption, workingKey);
    });

    request.on('end', function () {
      let pData = '';
      pData = '<table border=1 cellspacing=2 cellpadding=2><tr><td>';
      pData = pData + ccavResponse.replace(/=/gi, '</td><td>');
      pData = pData.replace(/&/gi, '</td></tr><tr><td>');
      pData = pData + '</td></tr></table>';
      const htmlcode = '<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><title>Response Handler</title></head><body><center><font size="4" color="blue"><b>Response Page</b></font><br>' + pData + '</center><br></body></html>';
      setResponseData(htmlcode);
      response.writeHead(200, {"Content-Type": "text/html"});
      response.end(htmlcode);
    });
  }

  const server = http.createServer(handleResponse);
  server.listen(3000, () => {
    console.log('Server started on port 3000');
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: responseData }} />
  );
}

export default CCAvResponseHandler;
