import React, { memo, useEffect, useState } from "react";
import Silvers from "images/MainpageBanners/silvers.png";
import Carousel from "react-elastic-carousel";
import Noimg from "../images/no_img.jpg";
import { motion } from "framer-motion";
import newArrivals from "images/MainpageBanners/newArrivals.png";
import tw from "twin.macro";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getProduct, orderStatus } from "api/ProductApi";
import { currentRegister } from "utility/Constant";

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:(mr-2 my-2) last:mr-0 text-black font-medium rounded-sm transition duration-300 text-xs sm:text-sm w-1/2 sm:w-auto text-center uppercase border border-gray-400 border-solid`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

// eslint-disable-next-line
const Container = tw.div`text-center`;

function NewArrivalsComponent() {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    Promise.all([
      getOrderStatusSale(),
      getOrderStatusTransfer(),
      getOrderStatusSold(),
    ])
      // eslint-disable-next-line
      .then((result) => {
        getNewArrivals(result[0], result[1], result[2]);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line
  }, []);

  const getOrderStatusSale = () => {
    return orderStatus({ orderStatusName: "sale" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getNewArrivals = (
    orderSaleStatus,
    orderTransferStatus,
    orderSoldStatus
  ) => {
    if (orderSaleStatus && orderTransferStatus && orderSoldStatus) {
      getProduct(10, {
        removeNTP: "NTP-",
        removeHP: "HP-",
        beforeCreatedAt: currentRegister(),
        productDemoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
        productLabel: "New Arrival",
        statusId: `${orderSaleStatus}, ${orderTransferStatus}, ${orderSoldStatus}`,
      })
        .then((data) => {
          setCategoryList(data.response.content);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <motion.div
      className="row"
      initial={{ x: "50px", opacity: 0, scale: 0.5 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
    >
      <div className="col-md-6 ">
        <img
          src={newArrivals}
          alt="New Arrivals"
          className="w-100 rounded"
          tw="cursor-pointer"
          onClick={() => history.push("NewArrivals")}
        />
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-6 text-center">
            <h1 className="fw-bold" tw="md:m-0 my-5">
              New Arrivals
            </h1>
            <p className=" ptSans">
              This Month Take a glance at the fresh designs that have been added
              to our stock recently!
            </p>
            <TabControl
              key={1}
              active={false}
              onClick={() => history.push("NewArrivals")}
              className="m-auto fw-bold w-75 text-center"
              tw="rounded-lg"
            >
              View All
            </TabControl>
          </div>
          <div className="col-md-6">
            <img
              src={Silvers}
              alt="New Arrivals"
              className="w-100 d-sm-none  d-xl-block"
              tw="rounded"
            />
          </div>
        </div>
        <Carousel
          itemsToShow={3}
          itemPadding={[5, 5]}
          className="newArrivalsComponent align-items-center pt-4"
        >
          {categoryList.map((data, index) => (
            <img
              src={data.images === "@#@" ? Noimg : data.images.split("@#@")[0]}
              alt="New Arrivals"
              className="rounded-circle border-solid border-1 border-custom-100"
              tw="cursor-pointer w-40 xl:h-40 lg:h-24"
              onClick={() => history.push("NewArrivals")}
              key={index}
            />
          ))}
        </Carousel>
      </div>
    </motion.div>
  );
}

export default memo(NewArrivalsComponent);
