import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import Select from 'react-select';
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { getProduct, orderStatus } from "../api/ProductApi.js";
import { currentRegister } from "utility/Constant.js";
import { Link, useHistory } from "react-router-dom";
import ProductCommon from "../components/ProductLike&Wish";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import no_data from "images/no_data.png";
import SkeletonLoader from "components/SkeletonLoader";
import { getBranchId } from "utility/HeaderConfig";

const Container = tw.div`relative font-display overflow-hidden`;
const TabContent = tw(motion.div)`block `;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto  sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;
export default ({ configImages }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(12);
  const [banner, setBanner] = useState([]);
  const [orderStatusSale, setOrderStatusSale] = useState("");
  const [orderStatusTransfer, setOrderStatusTransfer] = useState("");
  const [orderStatusSold, setOrderStatusSold] = useState("");
  const [selectedOption,setSelectedOption] = useState({id: 5,label:'SHOP OUR EXCLUSIVE',value: '/ShopOurExclusiveComponent'})
  
  
  
  const pageList =  [

    {id: 1,label:'NEW ARRIVALS',value:'/NewArrivals'},
    {id: 2,label:'MOST LIKED PRODUCT',value: '/MostLiked'},
    {id: 3,label:'OCCASION CATEGORIES',value:'/OccasionCategoriesComponent'},
    {id: 4,label:'TREND ON SHOPS',value:'/TrendOnShopComponent'},
    {id: 5,label:'SHOP OUR EXCLUSIVE',value: '/ShopOurExclusiveComponent'},
    {id: 6,label:'BEST SELLING',value:'BestSellingComponent'},
    
  ];
  useEffect(() => {
    Promise.all([
      getOrderStatusSale(),
      getOrderStatusTransfer(),
      getOrderStatusSold(),
      getBanner(),
    ])
      // eslint-disable-next-line
      .then((result) => {
        getShopOurExclusive(result[0], result[1], result[2]);
      })
      .catch((err) => console.error(err));
    //eslint-disable-next-line
  }, []);

  const history = useHistory();

  const getOrderStatusSale = () => {
    return orderStatus({ orderStatusName: "sale" })
      .then((data) => {
        setOrderStatusSale(data.response[0].id);
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusTransfer = () => {
    return orderStatus({ orderStatusName: "Transferred" })
      .then((data) => {
        setOrderStatusTransfer(data.response[0].id);
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getOrderStatusSold = () => {
    return orderStatus({ orderStatusName: "Sold" })
      .then((data) => {
        setOrderStatusSold(data.response[0].id);
        return data.response[0].id;
      })
      .catch((err) => console.error(err));
  };

  const getShopOurExclusive = (
    orderSaleStatus,
    orderTransferStatus,
    orderSoldStatus
  ) => {
    setLoading(true);
    setSize(2000);
    if (orderSaleStatus && orderTransferStatus && orderSoldStatus) {
      getProduct(size, {
        branchId: getBranchId(),
        productLabel: 'Shop Our Exclusives',
        removeNTP: "NTP-",
        removeHP: "HP-",
        beforeCreatedAt: currentRegister(),
        sortType: true,
        demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
        statusId: `${orderSaleStatus},${orderTransferStatus},${orderSoldStatus}`,
      })
        .then((data) => {
          setCategoryList(data.response.content);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedOption(menuItem)
    history.push(menuItem.value)    
  };

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "newArrivals") {
          setBanner(data.value);
        }
      });
  };

  return (
    <Container>
      {banner && banner.length > 0 ? (
        <img src={banner} alt="New Arrivals Products" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          Shop Our Exclusives
        </span>
      </div>

      <div className="py-sm-5  pb-2 mb-5 position-relative">
        <div className="container container-influid-md">
          <div className="row justify-content-between align-items-center pb-4">
            <div className="col-md-2">
              <div id="holder" tw="sm:pb-0 pb-5">
                <div className="button">
                  <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                    <i className="bi bi-cart4 mx-2 fw-bold"></i> Shop By
                    <i className="bi bi-arrow-bar-right fw-bold mx-2"></i>
                  </p>
                  <div className="btnTwo">
                    <p className="btnText2">
                      <i className="bi bi-cart3"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
              <Select
                  options={pageList}
                  className="select-Box"
                  name="gender"
                  id="style-11"
                  value={selectedOption}
                  onChange={handleMenuItemClick}
                >
                </Select>
              </div>
            </div>
          </div>
          <div className="row">
            {categoryList.length === 0 && !loading ? (
              <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
            ) : (
              categoryList.map((categoryItem, index) => (
                <TabContent
                  key={index}
                  transition={{ duration: 0.4 }}
                  className="col-md-6 col-lg-4 col-xl-3"
                >
                  <CardContainer>
                    <ProductCommon categoryItem={categoryItem} />
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                      onClick={() =>
                        history.push("/ProductDetails", categoryItem)
                      }
                    >
                      <div className="overflow-hidden">
                        <CardImageContainer
                          imageSrc={
                            categoryItem.images === "@#@"
                              ? Noimg
                              : categoryItem.images.split("@#@")[0]
                          }
                          tw="transform transition duration-500  group-hover:(scale-125 rotate-12)"
                        ></CardImageContainer>
                        <div
                          className="badge"
                          tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1 "
                        >
                          {categoryItem.stockStatus === "Sale" && "Available"}
                        </div>
                      </div>
                      <CardText>
                        <div className="d-flex justify-content-between mb-1 align-items-center">
                          <div className="col-md-7">
                            <CardTitle className="capitalize">
                              {categoryItem.metalType}
                            </CardTitle>
                            <CardContent
                              className="capitalize"
                              tw="truncate w-full"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={categoryItem.title}
                            >
                              {categoryItem.category}
                            </CardContent>
                          </div>
                          <div className="col-md-5 text-end">
                            <b tw="text-lg flex justify-end tracking-wide">
                              {" "}
                              <span className="mx-1">&#8377;</span>{" "}
                              {Math.trunc(categoryItem.total)}
                            </b>
                            {categoryItem.goldPurity !== "-" && (
                              <small tw="text-xs">
                                {categoryItem.goldPurity}
                              </small>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </Card>
                  </CardContainer>
                </TabContent>
              ))
            )}

            {loading && <SkeletonLoader />}
            <div className="d-flex justify-content-center pt-5 mt-3">
              <div
                type="button"
                tw="border border-custom-100 hover:text-white hover:bg-custom-100 px-20 py-2 text-sm"
                onClick={() => {
                  getShopOurExclusive(
                    orderStatusSale,
                    orderStatusTransfer,
                    orderStatusSold
                  );
                }}
              >
                {loading ? "Loading ..." : "View More"}
              </div>
            </div>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </div>
        </div>
      </div>
    </Container>
  );
};
