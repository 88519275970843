import React, { memo } from "react";
import { motion } from "framer-motion";
import  tw from  "twin.macro";

//eslint-disable-next-line
const Heading = tw.h1`text-black`;

function RemoveCart( props ) {
  return (
    <motion.div
      initial={{ x: "700px", opacity: 0, scale: 0.5 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
      tw="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
    >
      {" "}
      <div tw="relative" className=" col-md-7 col-lg-6 col-xl-5  ">
        <div
          className="box "
          tw="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none "
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div
            className="box-inner"
            tw="flex-wrap sm:flex items-start justify-center items-center p-2 border-solid border-custom-100 rounded-t"
          >
            <div className="text-center">
              <i className="bi bi-trash" tw="text-5xl text-gray-700"></i>
              <h6 tw="text-lg my-5 leading-8">
                Are you sure you want to remove the product ?
              </h6>
              <div className="pt-4 py-sm-3 d-flex justify-content-evenly">
                <button
                  className="secondary-btn cart-btns false py-2 px-4 border border-danger rounded"
                  onClick={() => props.setShowModal("")}
                >
                  <span>Cancel</span>
                </button>
                <button
                  className="primary-btn cart-btns false hoverEffect py-2 px-3 px-sm-5 rounded"
                  onClick={() => props.removeItemFromCart(props.showModal)}
                >
                  <span>Remove</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default memo(RemoveCart);
