import React, { useEffect, useState } from "react";
import { NavLink } from "./ProductCategory";
import Noimg from "../images/no_img.jpg";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import no_data from "images/no_data.png";
import Carousel from "react-elastic-carousel";
import { getProductCate } from "api/ProductCategoryApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



const TabContent = tw(motion.div)`block block w-full cursor-pointer`;

const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

const CardContainer = tw.div`mt-4 w-full`;
const Card = tw(
  motion.a
)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative rounded `}
`;

function OccationCategories() {
  const history = useHistory();

  const [occationCate, setOccationCate] = useState([]);

  useEffect(() => {
    getOurExclusiveData();
  }, []);

  const getOurExclusiveData = () => {
    getProductCate({
      visibilityMode: "DESIGN",
    })
      .then((data) => {
        setOccationCate(data.response.content);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];

  return (
    <>
      <div className="container py-2 text-center ">
        <div tw="flex justify-center">
          <Heading>Occasion Categories </Heading>
        </div>
      </div>
      <div className="container">
        {occationCate.length === 0 ? (
          <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
        ) : (
          <Carousel
            itemPadding={[0, 10]}
            itemsToShow={5}
            outerSpacing={0}
            showEmptySlots
            breakPoints={breakPoints}
            className="newArrivalsComponent"
          >
            {occationCate.map((categoryItem, index) => (
              <>
                <TabContent
                  key={index}
                  transition={{ duration: 0.4 }}
                  className="col-md-6 col-lg-4 col-xl-3"
                  style={{ paddingTop: "20px" }}
                >
                  <div className="w-100" key={index}>
                    <CardContainer
                      onClick={() =>
                        history.push("/OccasionCategoriesComponent",{
                          occasionCate : categoryItem
                        })
                      }
                      tw="cursor-pointer relative"
                    >
                      <Card className="group position-relative ">
                        <div className="overflow-hidden">
                          <CardImageContainer
                            className="group"
                            tw="h-64 border border-gray-300 rounded-t-lg flex justify-center w-full  transform transition duration-500  group-hover:(scale-125 rotate-12)"
                            imageSrc={
                              categoryItem.imageUrl === "Image_Not_Available" ||
                              categoryItem.imageUrl === "-" ||
                              categoryItem.imageUrl === null
                                ? Noimg
                                : categoryItem.imageUrl
                            }
                          ></CardImageContainer>
                        </div>
                        <div tw="text-center  border border-gray-300  font-semibold bg-white px-5 py-3 text-black text-xs uppercase">
                          <NavLink className="mb-2">
                            {categoryItem.displayName}
                          </NavLink>
                        </div>
                      </Card>
                    </CardContainer>
                  </div>
                </TabContent>
              </>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
}

export default OccationCategories;
