import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getAddress, getAllAddress } from "api/AddressApi";
import { getCustomerId } from "../utility/HeaderConfig";
// import AddAddress from "./AddAddress";

 const getInitialState = () => {
   const initialState = {
     customerId: getCustomerId(),
     customerName: "",
     mobileNo: "",
     pincodeNumber: "",
     stateName: "",
     address2: "",
     address1: "",
     latitude: "",
     longitude: "",
     landmark: "",
     districtName: "",
     isButtonPressed: false,
   };
   return initialState;
 };

function ViewAddress(props,ref) {
  const [state, setState] = useState(getInitialState());
  const [addressList, setAddressList] = useState([]);

useImperativeHandle(ref,()=>({
  click:()=>{
    getAddressDetails()
  }
}))

  useEffect(() => {
    getAddressDetails();

    //eslint-disable-next-line
  }, []);

  const getAddressDetails = () => {
    let customerId = getCustomerId();
    let filter = {
      customerEntity: customerId,
    };
    getAllAddress(filter)
      .then((data) => {
        console.log(data,"Nirmal");
        setAddressList(data.response);
      })
      .catch((err) => console.error(err));
  };

  const getAddressById = (id) => {
    const responseData = getAddress(id);
    responseData.then((data) => {
      setState((prev) => ({
        ...prev,
        id: data.response.id,
        customerName: data.response.customerName,
        mobileNo: data.response.mobileNo,
        pincodeNumber: data.response.pincodeNumber,
        stateName: data.response.stateName,
        address2: data.response.address2,
        address1: data.response.address1,
        latitude: data.response.latitude,
        longitude: data.response.longitude,
        landmark: data.response.landmark,
        districtName: data.response.districtName,
      }));
      props.updateProps(
        data.response.id,
        data.response.customerName,
        data.response.mobileNo,
        data.response.pincodeNumber,
        data.response.stateName,
        data.response.address2,
        data.response.address1,
        data.response.latitude,
        data.response.longitude,
        data.response.landmark,
        data.response.districtName
      );
    });
  };



  return (
    <div className="col-md-4  my-sm-0 my-4">
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-xl mx-2">
        <div className="px-5" tw="py-2 border-b-4 border-custom-100">
          <b>View Address</b>
        </div>
        <div className="table table-borderless m-0">
          {addressList.length > 0
            ? addressList.map((item, index) => (
                <>
                  <div
                    className="mt-5 mx-3"
                  >
                    <p key={index}>{item.customerName}</p>
                    <span> {item.pincodeNumber}</span>
                    <span> {item.address1}</span>
                    <span>{item.stateName}</span>
                    <input type="checkbox" className="mx-5" />
                    <br />
                    <button
                      className="btn btn-primary m-2"
                      onClick={()=>getAddressById(item.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => props?.deleteItem(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ViewAddress);
