import Carousel from "react-elastic-carousel";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Noimg from "../images/no_img.jpg";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import no_data from "images/no_data.png";
import ProductCommon from "../components/ProductLike&Wish";

const TabContent = tw(motion.div)`block block w-full cursor-pointer`;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto   max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;

function GenderSlider({ categoryList, Loading }) {
  const history = useHistory();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];

  return (
    <>
      {categoryList.length > 0 ? (
        <Carousel
          itemPadding={[0, 10]}
          itemsToShow={3}
          outerSpacing={0}          
          showEmptySlots
          breakPoints={breakPoints}
          className="newArrivalsComponent "
        >
          {categoryList.map((categoryItem, index) => (
            <TabContent
              key={index}
              transition={{ duration: 0.4 }}
              className="col-md-6 col-lg-4 col-xl-3"
              style={{ paddingTop: "20px" }}
            >
              <CardContainer>
                <Card className="group">
                  <ProductCommon categoryItem={categoryItem} />
                  <div className="overflow-hidden">
                    <CardImageContainer
                      imageSrc={
                        categoryItem.images === "@#@"
                          ? Noimg
                          : categoryItem.images.split("@#@")[0]
                      }
                      onClick={() =>
                        history.push("/ProductDetails", categoryItem)
                      }
                      tw="transform transition duration-500  group-hover:(scale-125 -rotate-12)"
                    ></CardImageContainer>
                    <div
                      className="badge"
                      tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                    >
                      {categoryItem.stockStatus === "Sale" && "Available"}
                    </div>
                  </div>
                  <CardText>
                    <div className="d-flex justify-content-between mb-1 align-items-center">
                      <div className="col-md-7">
                        <CardTitle className="capitalize">
                          {categoryItem.metalType}
                        </CardTitle>
                        <CardContent
                          className="capitalize"
                          tw="truncate w-full "
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={categoryItem.title}
                        >
                          {categoryItem.title}
                        </CardContent>
                      </div>
                      <div className="col-md-5 text-end">
                        <b tw="text-lg flex justify-end">
                          <span className="mx-1">&#8377;</span>{" "}
                          {Math.trunc(categoryItem.total)}
                        </b>
                        {categoryItem.goldPurity !== "-" && (
                          <small tw="text-xs">{categoryItem.goldPurity}</small>
                        )}
                      </div>
                    </div>
                  </CardText>
                </Card>
              </CardContainer>
            </TabContent>
          ))}
          {Loading && (
            <div className="w-100 py-2">
              <Skeleton tw="w-full" style={{ height: "260px" }} />
              <div className="card my-2 text-center py-2">
                <Skeleton className="px-3" tw="w-11/12" />
                <Skeleton className="px-3" tw="w-11/12" />
                <Skeleton className="px-3" tw="w-11/12" />
                <Skeleton className="px-3" tw="w-11/12" />
              </div>
            </div>
          )}
        </Carousel>
      ) : (
        <img src={no_data} alt="no-data" className="col-md-4" />
      )}
    </>
  );
}

export default GenderSlider;
