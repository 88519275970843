import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import { motion } from "framer-motion";
// import success from "images/OrderStatus/success.gif";
// import decline from "images/OrderStatus/decline.gif";

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

function OrderStatusPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");


// Get the value of the 'response' query parameter from the URL
var queryParams = new URLSearchParams(window.location.search);
var responseText = queryParams.get('status');

// Parse the JSON data into a JavaScript object
var response = JSON.parse(decodeURIComponent(responseText));

// Access the properties of the response object
console.log(response);
// ...etc.


  return (
    <div className="bg-image">
      <motion.div
        initial={{ x: "700px", opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        tw="justify-center  flex overflow-x-hidden overflow-y-auto py-32 outline-none focus:outline-none "
      >
        <div tw="relative" className="col-12 col-md-7 col-lg-8 col-xl-10  ">
          <div
            className="box w-100"
            tw="shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none "
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: `${
                "success" === "success" ? "#f7f7f7" : "#fff"
              }`,
            }}
          >
            {/* <div
              className="box-inner p-2"
              tw="flex-wrap sm:flex items-start justify-center items-center  border-solid border-custom-100 rounded-t"
            >
              <div className="text-center">
                <img
                  src={`${
                    "success" === "success" ? success : decline
                  } `}
                  alt="Payment Success"
                  className="w-50 m-auto"
                />
                <h4
                  tw="text-2xl my-5 leading-8 uppercase tracking-wider"
                  className={`${
                    "success" === "success"
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  Payment {OrderStatus} !
                </h4>
                <h5
                  tw="text-xl my-5 leading-8 uppercase tracking-wider"
                  className={`${
                    "success" === "success"
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  Your Order Has Been {OrderStatus} !
                </h5>
                <h4 tw="text-base  text-yellow-700 leading-8 uppercase tracking-wider">
                  Order Id :{" "}
                  {transactionDetails !== ""
                    ? transactionDetails.OrderKeyId
                    : ""}
                </h4>
                <h4 tw="text-base  text-yellow-700 leading-8 uppercase tracking-wider">
                  Payment Transaction Id :{" "}
                  {transactionDetails !== ""
                    ? transactionDetails.PaymentTransactionId
                    : ""}
                </h4>
                <div className="pt-4 py-sm-3 d-flex justify-content-evenly">
                  <button
                    className="primary-btn cart-btns false hoverEffect py-2 px-3 px-sm-4 rounded-pill"
                    onClick={() => history.push("/ProductList")}
                  >
                    <span>Continue Shopping</span>
                  </button>
                </div>
              </div>
            </div> */}
            <p>Status: {status}</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default memo(OrderStatusPage);
