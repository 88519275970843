import React, { useEffect, useState } from 'react';
import tw from "twin.macro";
import { encrypt } from './ccavutil';
import Divider from "images/MyScheme/divider.png";
import { useLocation } from 'react-router-dom';
import { dateMonthYearFormatUtcToLocal } from 'utility/Constant';

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

const CCAvRequestHandler = () => {
  const [formBody, setFormBody] = useState('');
  let location = useLocation();
  let myparam = location.state;

useEffect(()=>{
  initiateTransaction();
  //eslint-disable-next-line
},[])

  const initiateTransaction = async () => {
    const merchantId = "1207638"; // Replace with your actual merchant ID
    const accessCode = 'AVMB11JG46BU36BMUB'; // Replace with your actual access code
    const workingKey = '40187F7FFE7EA2CF83B2189F232AAA8E'; // Replace with your actual working key
    const plainText = `merchant_id=${myparam.merchant_id}&order_id=${myparam.order_id}&currency=${myparam.currency}&amount=${myparam.amount}&redirect_url=${myparam.redirect_url}&cancel_url=${myparam.redirect_url}&language=${myparam.language}&billing_name=${myparam.billing_name}&billing_address=${myparam.billing_address}&billing_city=${myparam.billing_city}&billing_state=${myparam.billing_state}&billing_zip=${myparam.billing_zip}&billing_tel=${myparam.billing_tel}&merchant_param1=additionalInfo&merchant_param2=${myparam.merchant_param2}&merchant_param3=${myparam.merchant_param3}&merchant_param4=additionalInfo&merchant_param5=additionalInfo&integration_type=iframe_normal&promo_code=null&customer_identifier=null`

    var encRequest = encrypt(plainText, workingKey);
    // Build the form body with the encrypted request
    const newFormBody = `
      <html>
        <head>
          <title>Sub-merchant checkout page</title>
          <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
        </head>
        <body>
          <center>
            <!-- width required minimum 482px -->
            <iframe
              width="100%"
              height="500"
              scrolling="No"
              frameborder="0"
              id="paymentFrame"
              src="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${merchantId}&encRequest=${encRequest}&access_code=${accessCode}"
            ></iframe>
          </center>
          <script type="text/javascript">
            $(document).ready(function() {
              $("iframe#paymentFrame").load(function() {
                window.addEventListener(
                  "message",
                  function(e) {
                    $("#paymentFrame").css("height", e.data["newHeight"] + "px");
                  },
                  false
                );
              });
            });
          </script>
        </body>
      </html>
    `;
    setFormBody(newFormBody);
  };

  return (
    <div className='w-100 h-100 ' tw="bg-zinc-100">
      {/* Render the form body */}
      {formBody ? (
        <div className='row m-0'>
          <div className='col-md-5'>
             <div className='p-5'>
                <div tw="uppercase mb-3 tracking-wide text-2xl text-custom-100 font-semibold text-center">
                  My Scheme Details
                  <img
                    src={Divider}
                    alt="heading-divider"
                    tw="m-auto mt-1 w-1/6"
                  />
                  </div>
                   <div className="accordion" id="accordionExample">
                      <div className="accordion-item bg-transparent border-0">
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button text-uppercase  bg-white rounded-1 text-dark`}  
                            tw="bg-white border-primary-500"                        
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#one`}
                          >
                            Profile Details
                          </button>
                        </h2>
                        <div
                          id={`one`}
                          className={`accordion-collapse collapse show ptSans`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body d-flex px-5">
                           <div className='w-50' >
                                <p>Name</p>
                                <p>Branch</p>
                                <p>Mobile</p>
                                <p>Email Id</p>
                                <p>Address</p>
                           </div>
                           <div className='w-50'>
                                <p>{myparam.name}</p>                               
                                <p>{myparam.branchName}</p>
                                <p>{myparam.mobile}</p>
                                <p>{myparam.email}</p>
                                <p>{myparam.address1}</p>
                           </div>
                          </div>
                        </div>
                      </div>
                   </div>

                   <div className="accordion" id="accordionExample2">
                      <div className="accordion-item bg-transparent border-0">
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button text-uppercase  bg-white rounded-1 text-dark collapsed`}  
                            tw="bg-white border-primary-500"                        
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#two`}
                          >
                            Scheme Details
                          </button>
                        </h2>
                        <div
                          id="two"
                          className="accordion-collapse collapse  ptSans"
                          data-bs-parent="#accordionExample2"
                        >
                          <div className="accordion-body d-flex px-5">
                           <div className='w-50'>
                                <p>Chit Id</p>
                                <p>Enroll DT</p>
                                <p>Completed DT</p>
                                <p>Duration</p>
                                <p>Due Amount</p>
                                <p>Last Due No</p>
                           </div>
                           <div className='w-50 text-end'>
                                <p>{myparam.chitId}</p>
                                <p> {dateMonthYearFormatUtcToLocal(myparam.enrollmentDate)}</p>
                                <p>{dateMonthYearFormatUtcToLocal(myparam.scheduleCompletionDate)}</p>
                                <p>{myparam.noOfMonths}</p>                               
                                <p>{myparam.chitAmount}</p>
                                <p>{myparam.chitPaymentListSize}</p>
                           </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between  p-4'>
                      <p className='ptSans'>Total Amount</p>
                      <b>&#8377; {myparam.chitTotalPaymentMade}</b>
                    </div>
             </div>
          </div>
          <div className='col-md-7 custom_background'>
             <div dangerouslySetInnerHTML={{ __html: formBody }}></div>
          </div>
        </div>
      ) : (        
        <button onClick={initiateTransaction}   tw=" m-20 cursor-pointer h-10 px-4 xl:px-2 text-white transition-colors duration-200  rounded mx-2 bg-custom-100  hover: hover:(text-black bg-white)"
        className="border">Pay Now</button>
      )}
    </div>
  );
};

export default CCAvRequestHandler;
