import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import {  getHeaderConfig } from '../utility/HeaderConfig';

const getPageProductType = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/products/type/metadata/get-page?page=0&size=50&sort=createdAt,desc&metaProdTypeFilter=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};


const getAllProductType = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/products/type/metadata/get-all?metaProdCateFilter=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getByMetalTypeId = (id: string) => {
  return axios
  .get(
    `${BASE_URL}/products/type/metadata/get?id=${id}`,
    getHeaderConfig()
  )
  .then(response => {
    const respData = {
      responseCode: response.status,
      response: response.data
    };
    return respData;
  })
  .catch(error => {
    console.log(error);
    throw error;
  });
};

const getAllProductsType = (filters) => {
  return axios
    .get(`${BASE_URL}/products/type/metadata/get-all?metaProdTypeFilter=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

export {  getAllProductType , getPageProductType,getByMetalTypeId ,getAllProductsType};
