import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { getAllBranch } from "api/BranchDetailsApi";
import { Link } from "react-router-dom";

//eslint-disable-line
const BannerImg = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-contain py-24 relative rounded-t w-full sm:py-32 sm:bg-cover bg-center`}
`;

const StyledDiv = tw.div`font-display overflow-hidden`;
export default function Contact({ configImages }) {
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const getAllBrachDetails = () => {
      getAllBranch({}).then((data) => {
        setData(data.response.content);
      });
    };
    getAllBrachDetails();
    getBanner();
    //eslint-disable-next-line
  }, []);

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "contact") {
          setBanner(data.value);
        }
      });
  };

  return (
    <StyledDiv>
      <BannerImg tw="flex justify-center" imageSrc={banner}></BannerImg>
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">Contact</span>
      </div>
      <section className="bg-light py-5 mt-2 shadow-sm">
        <div className="container">
          <div className="row justify-content-center">
            {data.length > 0 && data.length === 2 &&
              data.map((data, index) => (
                <div className="col-lg-4 mb-3 d-flex align-items-stretch">
                  <div className="card">
                    <img
                      src={configImages && configImages.STORIMAGES[index].value}
                      className="card-img-top"
                      alt="Card img"
                    />
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title text-uppercase">
                        {data.storeName}{" "}
                        <small tw="text-sm block py-1 ">
                          {data.branchName}
                        </small>
                      </h5>
                      <p className="card-text mb-1">
                        <i
                          className="bi bi-shop"
                          tw="text-custom-100 text-xl mr-2"
                        ></i>{" "}
                        {data.branchAddress.address1 !== "-"
                          && data.branchAddress.address1 + data.branchAddress.address2}.
                      </p>
                      <p className="card-text mb-1">
                        <i
                          className="bi bi-envelope"
                          tw="text-custom-100 text-xl mr-2"
                        ></i>{" "}
                        {data.email}
                      </p>
                      <p className="card-text mb-1">
                        <i
                          className="bi bi-joystick"
                          tw="text-custom-100 text-xl mr-2"
                        ></i>{" "}
                        {data.branchAddress.districtName} -{" "}
                        {data.branchAddress.pincodeNumber}
                      </p>
                      <p className="card-text mb-1">
                        <i
                          className="bi bi-telephone"
                          tw="text-custom-100 text-xl mr-2"
                        ></i>{" "}
                        {data.countryCode} {data.mobile}
                      </p>
                      <a
                        className="w-100 mt-3 align-self-start"
                        href={`#getDirection${index}`}
                      >
                        <button
                          className="px-5 py-2 mx-0 text-uppercase hoverEffect"
                          tw="font-semibold w-full"
                        >
                          Get Directions
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <div className="row py-4">
            {data.length > 0 && (
              <div className="col-md-6 " id="getDirection0">
                <div className="box bg-light position-relative mw-100">
                  <div className="box-inner p-0">
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.409575295979!2d78.14309961470447!3d11.665358445345925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf048013d77e1%3A0x3919d217d8af579a!2sZinger%20Zinger%20Jewellers!5e0!3m2!1sen!2sin!4v1666787118914!5m2!1sen!2sin"
                      // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15628.037973207232!2d78.1098746!3d11.6937394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babfa788db1bcfb%3A0x83c01f3855c7e06b!2sRasi%20Infotech!5e0!3m2!1sen!2sin!4v1679916954190!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      // style="border:0;"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="branch one"
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
            {data.length > 1 && (
              <div className="col-md-6" id="getDirection1">
                <div className="box bg-light position-relative mw-100">
                  <div className="box-inner p-0">
                    <iframe
                      // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15628.037973207232!2d78.1098746!3d11.6937394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babfa788db1bcfb%3A0x83c01f3855c7e06b!2sRasi%20Infotech!5e0!3m2!1sen!2sin!4v1679916954190!5m2!1sen!2sin"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.409575295979!2d78.14309961470447!3d11.665358445345925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf048013d77e1%3A0x3919d217d8af579a!2sZinger%20Zinger%20Jewellers!5e0!3m2!1sen!2sin!4v1666787118914!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      // style="border:0;"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="branch one"
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </StyledDiv>
  );
}
